import React from 'react';
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Text,
} from '@chakra-ui/react';

export default function CautionAlertDialog({ onContinue, loading, btnTitle = 'null', cautionTitle, icon = false, px, agree, disagree, size, props }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const BTN = { _focus: { boxShadow: 'none' } };

  const handleClick = ({ bg }) => {
    onClose();
    onContinue(true);
  };

  return (
    <>
      {icon ? (
        <Text ml='3PX' cursor='pointer' {...props} onClick={onOpen}>
          {icon}
        </Text>
      ) : (
        <Button _focus={{ boxShadow: 'none' }} onClick={onOpen} isLoading={loading && loading} {...props}>
          {btnTitle}
        </Button>
      )}
      <AlertDialog size={size || 'sm'} motionPreset='slideInBottom' leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{cautionTitle}</AlertDialogHeader>

          <AlertDialogFooter>
            <Button {...BTN} ref={cancelRef} onClick={onClose}>
              {disagree || 'No'}
            </Button>
            <Button {...BTN} colorScheme='red' onClick={handleClick} ml={3}>
              {agree || 'Yes'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
