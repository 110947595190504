import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";

export const TopCards = ({ content = null }) => {
  if (!content) return false;
  const TEXT_ALIGN = { textAlign: ["center", "center", "left"] };

  return (
    <SimpleGrid
      columns={{ sm: 1, md: 3, lg: 4 }}
      spacing="20px"
      color="#fff"
      marginBottom="20px"
      p={["10px", "0"]}
    >
      {content.map((data, id) => {
        return (
          <Box
            p="20px 10px"
            borderRadius="5px"
            key={id}
            w="100%"
            bg={data.color ? data.color : "blue.500"}
          >
            <Text {...TEXT_ALIGN}>
              <small>{data.title}</small>
            </Text>
            <Heading size="lg" {...TEXT_ALIGN}>
              {data.number}
            </Heading>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};
