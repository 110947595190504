import React from "react";
import { PaginatedTable } from "../../../../components/PaginatedTable";
import { TransferTableBody } from "./tables/TransferTableBody";
import { TransferTableHead } from "./tables/TransferTableHead";

const TransferTable = ({ getTransactions, updateTable }) => {
  return (
    <PaginatedTable
      columns={TransferTableHead}
      data={
        <TransferTableBody
          getData={getTransactions}
          updateTable={() => updateTable()}
        />
      }
      length={getTransactions?.length}
      updateTable={(page) => updateTable(page)}
    />
  );
};

export default TransferTable;
