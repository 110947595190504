import { Tr, Td, Flex, Tooltip, Box } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { BiTrash, BiBlock } from "react-icons/bi";
import { nanoid } from "@reduxjs/toolkit";
import { Modal } from "../../../modal/index";
import EditSecurityForm from "../../../../components/EditSecurityForm";
import CautionAlertDialog from "../../../../components/CautionAlertDialog";
import { useSelector } from "react-redux";
import {
  banSecurity,
  unbanSecurity,
  deleteSecurity,
} from "../../service/getSecurities";
import { FaTimes } from "react-icons/fa";
import { FaUnlockAlt } from "react-icons/fa";

const ICON = { color: "#fc8181", fontSize: "1.3em" };

export const TableBody = ({ getSecurities, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);

  const handleDelete = (rowId) => {
    deleteSecurity(userId, rowId).then(() => updateTable());
  };

  const handleBan = (data) => {
    const payload = {
      ...data,
      status: "Banned",
      userId,
    };
    banSecurity(payload).then(() => updateTable());
  };

  return (
    <>
      {getSecurities &&
        getSecurities.map((x) => (
          <Tr key={nanoid()}>
            <Td>{x.estateName}</Td>
            <Td>{x.estateAddress}</Td>
            <Td>{x.phoneNumber}</Td>
            <Td>{x.email}</Td>
            <Td>
              <Flex justifyContent="space-between">
                <Modal icon={<FiEdit {...ICON} color="green" />}>
                  <EditSecurityForm
                    initialData={x}
                    updateTable={() => updateTable()}
                  />
                </Modal>
                <CautionAlertDialog
                  onContinue={() => handleDelete(x._id)}
                  cautionTitle="Are you sure you want to remove this security"
                  icon={<BiTrash {...ICON} />}
                />
                <CautionAlertDialog
                  onContinue={() => handleBan(x)}
                  cautionTitle="Are you sure you want to ban this security"
                  icon={<BiBlock {...ICON} color="black" />}
                />
              </Flex>
            </Td>
          </Tr>
        ))}
    </>
  );
};

export const BannedTableBody = ({ getSecurities, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);

  const ICON = {
    fontSize: "1em",
    textalign: "center",
    color: "#757575",
  };

  const handleDelete = (rowId) => {
    deleteSecurity(userId, rowId);
    updateTable();
  };

  const handleUnban = (data) => {
    const payload = {
      ...data,
      status: "Active",
      userId,
    };
    unbanSecurity(payload).then(() => updateTable());
  };

  return (
    <>
      {getSecurities &&
        getSecurities.map((x) => (
          <Tr key={nanoid()}>
            <Td>{x.estateName}</Td>
            <Td>{x.estateAddress}</Td>
            <Td>{x.phoneNumber}</Td>
            <Td>{x.email}</Td>
            <Td
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Tooltip label="remove">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  bg="#d9d9d9"
                  w="25px"
                  h="25px"
                  pr={1}
                  borderRadius="50%"
                >
                  <CautionAlertDialog
                    onContinue={() => handleDelete(x._id)}
                    cautionTitle="Are you sure you want to remove?"
                    icon={<FaTimes {...ICON} />}
                  />
                </Flex>
              </Tooltip>

              <Tooltip label="unban">
                <Box>
                  <CautionAlertDialog
                    onContinue={() => handleUnban(x)}
                    cautionTitle="Are you sure you want to unban this security?"
                    icon={<FaUnlockAlt {...ICON} />}
                  />
                </Box>
              </Tooltip>
            </Td>
          </Tr>
        ))}
    </>
  );
};
