import { FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { addErrandSubscription } from "../pages/errand/service/addErrandSubscription";

const AddSubscriptionForm = ({ updateTable, onClose }) => {
  const userId = useSelector((state) => state.auth.user);
  const initialValues = {
    subscriptionName: "",
    numberOfErrands: "",
    packagePrice: "",
    description: "",
    userId,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    addErrandSubscription(
      formValues,
      setLoading,
      setFormValues,
      initialValues,
      onClose
    ).then(() => updateTable());
  };
  return (
    <FormControl
      id="errandAddSubscription"
      display="flex"
      flexDirection="column"
    >
      <FormLabel>Subscription Name</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        id="name"
        name="subscriptionName"
        onChange={handleChange}
      />
      <FormLabel>Description</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        id="description"
        name="description"
        onChange={handleChange}
      />
      <FormLabel>Package Price</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        id="amount"
        name="packagePrice"
        onChange={handleChange}
      />
      <FormLabel>Number of Errands</FormLabel>
      <Input
        mb={8}
        variant="filled"
        type="text"
        id="point"
        name="numberOfErrands"
        onChange={handleChange}
      />
      <Button
        isLoading={loading}
        _hover={{ bg: "#8366A5" }}
        _focus={{ boxShadow: "none" }}
        bg="#8366A5"
        color="#fff"
        h={45}
        fontWeight="medium"
        onClick={handleSubmit}
      >
        Create Subscription
      </Button>
    </FormControl>
  );
};

export default AddSubscriptionForm;
