import React, { useState, useEffect } from 'react';
import { Flex, Box, SimpleGrid } from '@chakra-ui/react';
import DashboardCard from './component/DashboardCard';
import { PendingErrands } from './component/PendingErrands';
import { useSelector } from 'react-redux';
import { getDashboard, getErrands } from './service/homeApi';
import FullPageLoader from '../../components/full-page-loader';

export const Home = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [dashData, setDashData] = useState([]);
  const [errands, setErrands] = useState([]);

  useEffect(() => {
    getDashboard(setLoading, userId, setDashData);
    getErrands(setLoading, userId, setErrands, 'PENDING');

    const abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Flex direction='column' w='100%'>
      <SimpleGrid
        pt={[10, 9, 0]}
        columns={{ sm: 1, md: 2, lg: 4 }}
        color='white'
      >
        {dashData.map((content, key) => {
          return (
            <DashboardCard
              key={key}
              bgColor={content?.background}
              title={content?.title}
              img={content?.image}
              amount={content?.value}
              alt={content?.title}
            />
          );
        })}
      </SimpleGrid>
      <Flex
        py='5px'
        mx='15px'
        my='3px'
        flex={1}
        direction={['column', 'column', 'column', 'row']}
      >
        <Box
          flex={1}
          bg='#fff'
          boxShadow='0px 3px 6px #0000003B'
          borderRadius='10px'
          pb={8}
          w='100%'
        >
          <PendingErrands
            updateTable={(page) => getErrands(setLoading, userId, setErrands)}
            errands={errands.filter((item) => item.status === 'PENDING')}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
