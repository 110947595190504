import { useState } from "react";
import { FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { addErrandType } from "../pages/errand/service/addErrandType";

const AddErrandTypesForm = ({ updateTable, onClose }) => {
  const getUserId = useSelector((state) => state.auth.user);
  const [userId] = useState(getUserId);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    categoryName: "",
    description: "",
    point: "",
    userId,
  };
  const [formValues, setFormValues] = useState(initialValues);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    addErrandType(
      formValues,
      setLoading,
      setFormValues,
      initialValues,
      onClose
    ).then(() => updateTable());
  };

  return (
    <FormControl
      id="errandAddSubscription"
      display="flex"
      flexDirection="column"
    >
      <FormLabel>Errand</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        id="name"
        name="categoryName"
        onChange={handleChange}
      />
      <FormLabel>Description</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        id="description"
        name="description"
        onChange={handleChange}
      />
      <FormLabel>Point</FormLabel>
      <Input
        mb={8}
        variant="filled"
        type="text"
        id="point"
        name="point"
        onChange={handleChange}
      />
      <Button
        bg="#8366A5"
        _hover={{ bg: "#8366A5" }}
        _focus={{ borderRadius: "none" }}
        color="#fff"
        h={50}
        fontWeight="medium"
        onClick={handleSubmit}
        isLoading={loading}
      >
        Submit
      </Button>
    </FormControl>
  );
};

export default AddErrandTypesForm;
