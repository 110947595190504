import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const editErrandType = async (setLoading, payload, onClose) => {
  try {
    await http.put(AUTH_ROUTES.EDIT_ERRAND_TYPE(payload.userId, payload._id), payload);
    setLoading(false);
    successNotifier('Errand Type edited successfully');
    onClose();
  } catch (e) {
    errorNotifier(e?.response?.data);
    setLoading(false);
  }
};
