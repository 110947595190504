import http, { AUTH_ROUTES } from '../../../services/api';
// import { errorNotifier } from "../../../components/NotificationHandler";
import { DATA_ROWS } from '../../../app/constants';

const cardData = [
  {
    title: 'Active Subscription',
    number: '0',
    color: '#A08CA8',
    key: 'totalSubscriptions',
  },
  {
    title: 'Total Resident',
    number: '0',
    color: '#64E270',
    key: 'totalUsers',
  },
  {
    title: 'Paid Resident',
    number: '0',
    color: '#FFBDB5',
    key: 'totalPaidResidents',
  },
  {
    title: 'Pending Payment',
    number: '0',
    color: '#FF897A',
    key: 'totalPendingPayments',
  },
];

//DASHBOARD ANALYTICS
export const getDashboard = async (setLoading, userId, setDashData) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_DASHBOARD(userId));

    const info = cardData.map((val) => {
      return {
        ...val,
        number: data[val.key] || val.number,
      };
    });
    setDashData(info);
  } catch (e) {
    // errorNotifier(e?.response?.data.result);
    setLoading(false);
    setDashData(cardData);
  } finally {
    setLoading(false);
  }
};

// GET ALL SUBSCRIPTIONS
export const getSubscriptions = async (setLoading, userId, setSubscription, skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SUBSCRIPTIONS(userId, page, limit));
    setSubscription(data.subscriptions);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data.result);
    setSubscription([]);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
