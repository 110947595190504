import { Tr, Td, Box, HStack, Tooltip } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { BiBlock, BiTrash } from "react-icons/bi";
import AssignErrandBoyForm from "../../../../components/AssignErrandBoyForm";
import { Modal } from "../../../modal";
import CautionAlertDialog from "../../../../components/CautionAlertDialog";
import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { cancelErrand } from "../../service/cancelErrand";
import { deleteErrandBoy } from "../../service/deleteErrandBoy";
import EditErrandBoyForm from "../../../../components/editErrandBoyForm";
import { banErrandBoy } from "../../service/banErrandBoy";
import { EditErrandSub } from "../../../../components/EditErrandSubForm";
import { completeErrand } from "../../service/completeErrand";
import { deleteErrandSub } from "../../service/deleteErrandSub";
import { unbanErrandBoy } from "../../service/unbanErrandBoy";
import EditErrandTypeForm from "../../../../components/editErrandTypeForm";
import { FaUnlockAlt } from "react-icons/fa";

const MD_PADDING = { padding: "10px 5px !important" };
const ICON_COLOR = { color: "#fc8181" };

export const PendingTableBody = ({ errand, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);

  const handleContinue = (rowData) => {
    let payload = {
      ...rowData,
      numberOfErrands: rowData.numberOfErrands,
      userId,
      status: "CANCELED",
    };
    cancelErrand(payload, rowData._id).then(() => updateTable());
  };

  return (
    <>
      {errand &&
        errand.map((x) => {
          return (
            <Tr key={nanoid()} bg="#fff">
              <Td>{x.errandCategory}</Td>
              <Td>{x?.name}</Td>
              <Td>{x.errandDescription}</Td>
              <Td>{x.options}</Td>
              <Td>{x.tenants.houseUnit} </Td>
              <Td>{x.tenants.tenantName}</Td>
              <Td>{x.tenants.residentCode}</Td>
              <Td>{dayjs(x.createdAt).format("DD-MMM-YYYY")}</Td>
              <Td>{x.tenants.residentCode}</Td>
              <Td display="flex" flexDirection="column">
                <Modal title="Assign" buttonText="Assign" small bg="#4caa4c">
                  <AssignErrandBoyForm
                    errandData={x}
                    updateTable={() => updateTable()}
                  />
                </Modal>

                <CautionAlertDialog
                  btnTitle="Delete"
                  border="1px solid #dbdbdb"
                  mt="5px"
                  size="xs"
                  cautionTitle="Are you sure you want to cancel this errand?"
                  onContinue={() => handleContinue(x)}
                />
              </Td>
            </Tr>
          );
        })}
    </>
  );
};

export const OngoingTableBody = ({ errand, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);

  const handleCompleteErrand = (rowData) => {
    const payload = {
      ...rowData,
      status: "COMPLETED",
      userId,
    };
    completeErrand(payload).then(() => updateTable());
  };

  return (
    <>
      {errand &&
        errand.map((x) => {
          return (
            <Tr key={nanoid()} bg="#fff">
              <Td>{x.errandCategory}</Td>
              <Td>{x?.name}</Td>
              <Td>{x.errandDescription}</Td>
              <Td>{x.options}</Td>
              <Td>{x.tenants?.houseUnit} </Td>
              <Td>{x.tenants?.tenantName}</Td>
              <Td>{x.tenants?.residentCode}</Td>
              <Td>{dayjs(x.createdAt).format("DD-MMM-YYYY")}</Td>
              <Td {...MD_PADDING}>{x.tenants?.residentCode}</Td>
              <Td {...MD_PADDING} display="flex" flexDirection="column">
                <Modal
                  title="Re-Assign"
                  buttonText="Re-Assign"
                  small={true}
                  bg="#8366A5"
                >
                  <AssignErrandBoyForm
                    errandData={x}
                    updateTable={() => updateTable()}
                  />
                </Modal>

                <CautionAlertDialog
                  bg="#26D637"
                  _hover={{ bg: "#26D637" }}
                  btnTitle="Completed"
                  mt="5px"
                  size="xs"
                  cautionTitle="Are you sure you want to complete this errand?"
                  onContinue={() => handleCompleteErrand(x)}
                />
              </Td>
            </Tr>
          );
        })}
    </>
  );
};

export const CompletedTableBody = ({ errand }) => {
  return (
    <>
      {errand &&
        errand.map((x) => (
          <Tr key={nanoid()} bg="#fff">
            <Td>{x.errandCategory}</Td>
            <Td>{x.tenants?.tenantName}</Td>
            <Td>{x.errandDescription}</Td>
            <Td>{x.options}</Td>
            <Td>{x.tenants?.houseUnit} </Td>
            <Td>{x.tenants?.tenantName}</Td>
            <Td>{x.tenants?.residentCode}</Td>
            <Td>{dayjs(x.createdAt).format("DD-MMM-YYYY")}</Td>
            <Td>{x.tenants?.residentCode}</Td>
          </Tr>
        ))}
    </>
  );
};

export const CanceledTableBody = ({ errand }) => {
  return (
    <>
      {errand &&
        errand.map((x) => (
          <Tr key={nanoid()} bg="#fff">
            <Td>{x.errandCategory}</Td>
            <Td>{x.tenants?.tenantName}</Td>
            <Td>{x.errandDescription}</Td>
            <Td>{x.options}</Td>
            <Td>{x.tenants?.houseUnit} </Td>
            <Td>{x.tenants?.tenantName}</Td>
            <Td>{x.tenants?.residentCode}</Td>
            <Td>{dayjs(x.createdAt).format("DD-MMM-YYYY")}</Td>
            <Td>{x.tenants?.residentCode}</Td>
          </Tr>
        ))}
    </>
  );
};

export const SubscriptionTableBody = ({ errandSub, updateTable }) => {
  const { errandPackage } = errandSub;
  const userId = useSelector((state) => state.auth.user);

  const handleDelete = (id) => {
    const payload = { userId };
    deleteErrandSub(payload, id).then(() => updateTable());
  };

  return (
    <>
      {errandSub &&
        errandPackage?.map((x, index) => (
          <Tr key={nanoid()} bg="#fff">
            <Td {...MD_PADDING}>{x?.subscriptionName}</Td>
            <Td {...MD_PADDING}>{x?.description}</Td>
            <Td {...MD_PADDING}>{x?.packagePrice}</Td>
            <Td {...MD_PADDING}>{x?.numberOfErrands} </Td>

            <Td {...MD_PADDING}>
              <HStack justifyContent="space-evenly">
                <Tooltip label="Edit subscription">
                  <Box>
                    <Modal
                      icon={<FiEdit color="green" fontSize="1.6em" />}
                      title="Edit Errad Subscription"
                    >
                      <EditErrandSub
                        updateTable={() => updateTable()}
                        initialData={x}
                      />
                    </Modal>
                  </Box>
                </Tooltip>

                <Tooltip label="delete">
                  <Box>
                    <CautionAlertDialog
                      icon={<BiTrash {...ICON_COLOR} fontSize="1.6em" />}
                      cautionTitle="Are you sure you want to delete Subscription?"
                      mt="5px"
                      small
                      onContinue={() => handleDelete(x._id)}
                    />
                  </Box>
                </Tooltip>
              </HStack>
            </Td>
          </Tr>
        ))}
    </>
  );
};

export const BannedErrandBoysTableBody = ({ errandBoys, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);

  const handleDelete = (id) => {
    let payload = {
      userId,
    };
    deleteErrandBoy(payload, id).then(() => updateTable());
  };

  const handleUnban = (data) => {
    const payload = {
      ...data,
      userId,
      status: "Active",
    };
    unbanErrandBoy(payload).then(() => updateTable());
  };

  return (
    <>
      {errandBoys &&
        errandBoys.map((x) => (
          <Tr key={nanoid()} bg="#fff">
            <Td {...MD_PADDING}>{x.name}</Td>
            <Td {...MD_PADDING}>{x.description}</Td>
            <Td {...MD_PADDING}>{x.phoneNumber}</Td>
            <Td {...MD_PADDING}>{x.estateAddress}</Td>
            <Td {...MD_PADDING}>
              <Box display="flex" justifyContent="space-between" pr={5}>
                <Tooltip label="Edit">
                  <Box>
                    <Modal
                      icon={<FiEdit color="green" />}
                      title="Edit Errand Boy"
                    >
                      <EditErrandBoyForm
                        updateTable={() => updateTable()}
                        initialData={x}
                      />
                    </Modal>
                  </Box>
                </Tooltip>

                <Tooltip label="delete">
                  <Box>
                    <CautionAlertDialog
                      icon={<BiTrash {...ICON_COLOR} fontSize="1.3em" />}
                      cautionTitle="Are you sure you want to delete this errand boy?"
                      mt="5px"
                      small
                      onContinue={() => handleDelete(x._id)}
                    />
                  </Box>
                </Tooltip>

                <Tooltip label="unban">
                  <Box>
                    <CautionAlertDialog
                      onContinue={() => handleUnban(x)}
                      cautionTitle="Are you sure you want to unban this errand boy?"
                      icon={<FaUnlockAlt />}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Td>
          </Tr>
        ))}
    </>
  );
};
export const ErrandBoysTableBody = ({ errandBoys, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);

  const handleDelete = (id) => {
    let payload = {
      userId,
    };
    deleteErrandBoy(payload, id).then(() => updateTable());
  };

  const handleBan = (data) => {
    const payload = {
      ...data,
      userId,
      status: "Banned",
    };
    banErrandBoy(payload).then(() => updateTable());
  };

  return (
    <>
      {errandBoys &&
        errandBoys.map((x) => (
          <Tr key={nanoid()} bg="#fff">
            <Td>{x.name}</Td>
            <Td>{x.description}</Td>
            <Td>{x.phoneNumber}</Td>
            <Td>{x.estateAddress}</Td>
            <Td>
              <HStack>
                <Tooltip label="Edit">
                  <Box>
                    <Modal
                      icon={<FiEdit color="green" />}
                      title="Edit Errand Boy"
                    >
                      <EditErrandBoyForm
                        updateTable={() => updateTable()}
                        initialData={x}
                      />
                    </Modal>
                  </Box>
                </Tooltip>

                <Tooltip label="delete">
                  <Box>
                    <CautionAlertDialog
                      icon={<BiTrash {...ICON_COLOR} fontSize="1.3em" />}
                      cautionTitle="Are you sure you want to delete this errand boy?"
                      mt="5px"
                      small
                      onContinue={() => handleDelete(x._id)}
                    />
                  </Box>
                </Tooltip>

                <Tooltip label="ban">
                  <Box>
                    <CautionAlertDialog
                      onContinue={() => handleBan(x)}
                      cautionTitle="Are you sure you want to ban this errand boy?"
                      icon={<BiBlock />}
                    />
                  </Box>
                </Tooltip>
              </HStack>
            </Td>
          </Tr>
        ))}
    </>
  );
};

export const ErrandTypesBody = ({ errandTypes, updateTable }) => {
  return (
    <>
      {errandTypes &&
        errandTypes?.map((data) => {
          return (
            <Tr key={nanoid()}>
              <Td>{data.categoryName}</Td>
              <Td>{data.description}</Td>
              <Td>{data.point}</Td>
              <Td color="red">
                <Tooltip label="Edit Errand type">
                  <Box>
                    <Modal
                      icon={<FiEdit fontSize="1.3em" />}
                      title="Edit Errand Category"
                    >
                      <EditErrandTypeForm
                        updateTable={() => updateTable()}
                        data={data}
                      />
                    </Modal>
                  </Box>
                </Tooltip>
              </Td>
            </Tr>
          );
        })}
    </>
  );
};
