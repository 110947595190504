import { Td } from "@chakra-ui/react"

export const TableHeadMobile = () => {
    return (
        <>
             <Td> S/N</Td>
             <Td> Offer Type</Td>
             <Td> Category</Td>
             <Td> Total Clicks</Td>
        </>
    )
}