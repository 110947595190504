import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { editErrandBoy } from "../pages/errand/service/editErrandBoy";

const EditErrandBoyForm = ({ updateTable, onClose, initialData }) => {
  const getUserId = useSelector((state) => state.auth.user);
  const [userId] = useState(getUserId);

  const [formValues, setFormValues] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    setLoading(true);

    const payload = {
      ...formValues,
      userId,
    };
    editErrandBoy(payload, setLoading, onClose).then(() => updateTable());
  };
  return (
    <FormControl id="createCategories" display="flex" flexDirection="column">
      <FormLabel>Name</FormLabel>
      <Input
        mb={4}
        value={formValues.name}
        variant="filled"
        type="text"
        name="name"
        onChange={handleChange}
      />
      <FormLabel>Phone Nmuber</FormLabel>
      <Input
        mb={4}
        value={formValues.phoneNumber}
        variant="filled"
        type="text"
        name="phoneNumber"
        onChange={handleChange}
      />
      <FormLabel>Estate Address</FormLabel>
      <Input
        mb={4}
        variant="filled"
        value={formValues.estateAddress}
        type="text"
        name="estateAddress"
        onChange={handleChange}
      />
      <FormLabel>Description</FormLabel>
      <Textarea
        mb={8}
        variant="filled"
        value={formValues.description}
        h={40}
        name="description"
        onChange={handleChange}
      />
      <Button
        bg="#8366A5"
        _hover={{ bg: "#8366A5" }}
        _focus={{ boxShadow: "none" }}
        color="#fff"
        h={50}
        fontWeight="medium"
        onClick={handleUpdate}
        isLoading={loading}
      >
        Update
      </Button>
    </FormControl>
  );
};

export default EditErrandBoyForm;
