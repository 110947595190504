import { Box, Text } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import { LinkTo } from "../../Nav/components/LinkTo";
import "./index.css";
import { BiLogOut } from "react-icons/bi";
import { SECURITY_NAV_ITEMS } from "./components/sidebar.constant";
import { useEffect, useState } from "react";
import { getEstateInfo } from "../../../pages/appSetting/service";
import { useSelector } from "react-redux";

const SideBar = ({ toggle }) => {
  const userId = useSelector((state) => state.auth.user);
  const [estateInfo, setEstateInfo] = useState({});

  useEffect(() => {
    getEstateInfo(userId, setEstateInfo);
  }, [userId]);

  return (
    <Box className="sidebar">
      <Box className="sidebar-inner">
        <Flex className="brand">
          <Text className="brand-style" p="10px" fontSize={["15px"]}>
            {estateInfo?.estateName}
          </Text>
        </Flex>

        <Flex flex={1} flexDirection="column">
          {SECURITY_NAV_ITEMS.map(({ title, to, icon: Icon }, id) => (
            <LinkTo
              key={id}
              to={to}
              title={title}
              toggle={toggle}
              id={1}
              iconComponent={<Icon />}
            />
          ))}
        </Flex>

        <Box
          size="md"
          color="#fff"
          fontSize=".9em"
          height="200px"
          display="flex"
          alignItems="end"
        >
          <LinkTo
            logout
            type="admin"
            btn
            to="/log_out"
            title="Logout"
            id={2}
            iconComponent={<BiLogOut />}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default SideBar;
