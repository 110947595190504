/**
 * Paths available to users who are not logged in
 * @constant
 */
export const PUBLIC_PATHS = {
  LANDING: '/',
  LOGIN: '/auth/login',
};

/**
 * Paths available to users who are  logged in
 * @constant
 */
export const PROTECTED_PATHS = {
  HOME: '/home',
  SHOP: '/shop',
  SHOP_CATEGORY: '/shop/category',
  ERRAND: '/errands',
  FINANCE: '/finances',
  FUND_WALLET: '/finances/fund-wallet',
  SUBSCRIPTION: '/subscriptions',
  WITHDRAWAL: '/withdrawal',
  NOTIFICATION: '/notifications',
  OFFERS: '/offers',
  APP_SETTING: '/app-setting',
  MANAGER: '/manager',
  LOGIN: '/login',
  CHANGE_PASSWORD: '/change-password',
  BANNER_SETTINGS: '/banner-setting',
};

/**
 * the number of table rows data that will be display
 * @constant
 */
export const DATA_ROWS = {
  LIMIT: 6,
};
