import { TableBody } from "./table-data/TableBody";
import { TableBodyMobile } from "./table-data/TableBodyMobile";
import { TableHead } from "./table-data/TableHead";
import { TableHeadMobile } from "./table-data/TableHeadMobile";
import { GlobalTable } from "../../../withdrawal/components/global-table-template";
import { Box } from "@chakra-ui/layout";

export const AccordionTable = ({ topOffers }) => {
  return (
    <Box bg="#fff" p={["5px", "10px 20px"]}>
      <GlobalTable
        mobileHead={<TableHeadMobile />}
        mobileBody={<TableBodyMobile offers={topOffers} />}
        tableHead={<TableHead />}
        tableBody={<TableBody offers={topOffers} />}
      />
    </Box>
  );
};
