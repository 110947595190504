import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Box,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import TableRow from "./TableRow";
import { nanoid } from "@reduxjs/toolkit";

export const PendingErrands = ({ errands, updateTable }) => {
  const history = useHistory();

  return (
    <Box p="15">
      <Box borderBottom="2px solid #FAF6FE">
        <Flex
          mb="20px"
          pr={[0, 10]}
          pb={2}
          justifyContent="space-between"
          color="#614285"
          borderBottom="2px solid #FAF6FE"
        >
          <Text fontWeight="bold" fontSize="16px">
            Pending Errands
          </Text>
          <Button
            size="xs"
            variant="ghost"
            onClick={() => history.push("/errands")}
            _focus={{ borderRadius: "none" }}
          >
            View All
          </Button>
        </Flex>
        <Table varaiant="simple" size="sm" color="#614285">
          <Thead>
            <Tr>
              <Th
                p={"5px !important"}
                fontWeight="normal"
                textTransform="capitalize"
                color="#B2B2B2"
              >
                House No
              </Th>
              <Th
                p={"5px !important"}
                fontWeight="normal"
                textTransform="capitalize"
                color="#B2B2B2"
              >
                Errand Type
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {errands?.map((val, i) => (
              <TableRow
                updateTable={() => updateTable()}
                key={nanoid()}
                SN={i + 1}
                id={val._id}
                houseNo={val.userId?.residentCode}
                type={val.errandCategory}
                errand={val}
              />
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};
