import { Button } from "@chakra-ui/button"
import { Input } from "@chakra-ui/input"
import { Flex, Box, Heading, VStack, Text } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/image"
import illustration from "../../assets/Illustration.svg"
import { useState } from "react"
import { changePassword } from "./service"
import { useSelector } from "react-redux"
import { useEffect } from "react"
export const ChangePassword = () => {
  const userId = useSelector(state => state.auth.user)
  const [loading, setLoading] = useState(false)
  const initialValues = {
    currentPassword: "",
    newPassword: "",
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [disabled, setDisabled] = useState(true)
  const INPUT_STYLE = {
    fontSize: ".9em",
  }

  //inputs must not be empty
  useEffect(() => {
    const { currentPassword, newPassword } = formValues
    if (!currentPassword || !newPassword) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [formValues])

  const handleChangePassword = () => {
    setLoading(true)

    changePassword(userId, formValues, setLoading)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }
  return (
    <Flex
      color="#3D3D3D"
      justifyContent="space-around"
      alignItems="center"
      bg="#fff"
      p="20px"
    >
      <Box>
        <Heading mb="5">Change password</Heading>
        <VStack spacing="6" alignItems="flex-start" w="100%">
          <Box w="100%">
            <Text fontSize=".8em" mb="5px" fontWeight="bold">
              Enter old password
            </Text>
            <Input
              type="password"
              name="currentPassword"
              onChange={handleChange}
              value={formValues.currentPassword}
              {...INPUT_STYLE}
              _placeholder={{ fontSize: ".8em", color: "grey" }}
              placeholder="Enter current password"
            />
          </Box>
          <Box w="100%">
            <Text fontSize=".8em" mb="5px" fontWeight="bold">
              Enter new password
            </Text>
            <Input
              type="password"
              name="newPassword"
              onChange={handleChange}
              {...INPUT_STYLE}
              value={formValues.newPassword}
              _placeholder={{ fontSize: ".8em", color: "grey" }}
              placeholder="Enter new password"
            />
          </Box>

          <Button
            color="#fff"
            bg="#FF6F6F"
            alignSelf="normal"
            _hover={{ bg: "#FF6F6F" }}
            _focus={{ boxShadow: "none" }}
            onClick={handleChangePassword}
            isLoading={loading}
            disabled={disabled}
          >
            Save
          </Button>
        </VStack>
      </Box>
      <Box display={["none", "none", "block"]}>
        <Image w="400px" h="500px" src={illustration} alt="Illustration" />
      </Box>
    </Flex>
  )
}
