import { errorNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getTenantProfile = async (setCheckLoading, userId, tenantId, setTenantProfile) => {
  try {
    const {
      data: { result },
    } = await http.get(AUTH_ROUTES.GET_TENANT_PROFILE(userId, tenantId));
    setTenantProfile(result.data);
    setCheckLoading(false);
  } catch (e) {
    setCheckLoading(false);
    errorNotifier(e.response?.data?.result?.message);
  }
};
