import { Td, Tr } from "@chakra-ui/table";
import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const FundedWalletListBody = ({ getData }) => {
  return (
    <>
      {getData &&
        getData.map((x) => {
          return (
            <Tr key={nanoid()}>
              <Td>{x.userId?.tenantName}</Td>
              <Td>₦ {parseInt(x.amount).toLocaleString()}</Td>
              <Td>{x.description}</Td>
              <Td>{dayjs(x?.createdAt).format("DD-MMM-YYYY")}</Td>
            </Tr>
          );
        })}
    </>
  );
};
