import { Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';

import { CustomTable } from '../../../../components/customized table/CustomTable';

export const Completedoffers = ({ completedOffers }) => {
  const completedfferColumns = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Item Name',
      accessor: 'itemName',
    },
    {
      Header: 'Amount',
      accessor: 'price',
    },
    {
      Header: 'Description',
      accessor: 'itemDescription',
    },
    {
      Header: 'Slots',
      accessor: 'initialSlots',
      Cell: ({ row }) => (
        <Text>{`${row.original.usedSlots} of ${row.original.initialSlots}`}</Text>
      ),
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => <Text>{dayjs(value).format('DD-MMM-YYYY')}</Text>,
    },
  ];

  return (
    <>
      <CustomTable COLUMNS={completedfferColumns} DATA={completedOffers} />
    </>
  );
};
