import { useHistory } from "react-router-dom"
import { Box, Flex, Text } from "@chakra-ui/layout"
import ShopTable from "./ShopTable"
import { Modal } from "../../modal"
import { useEffect, useState } from "react"
import CreateCategoryForm from "./CreateCategoryForm"
import { getCategories } from "../service/shopApi"
import { useSelector } from "react-redux"
import FullPageLoader from "../../../components/full-page-loader"
import { BsArrowLeft } from "react-icons/bs"
import { DATA_ROWS } from "../../../app/constants"

export const ViewCategories = () => {
  const userId = useSelector(state => state.auth.user)
  const history = useHistory()
  const [categoriesData, setCategoriesData] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getCategories(setLoading, userId, setCategoriesData)
    return () => {
      setCategoriesData([])
    }
  }, [userId])

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <Box p="20px" bg="#fff" paddingTop="30px">
        <Flex mb="10px" justifyContent="space-between">
          <Flex alignItems="center">
            <BsArrowLeft
              fontSize="1.5em"
              cursor="pointer"
              onClick={() => history.goBack()}
            />
            <Text
              marginLeft="50px"
              fontSize="14px"
              color="#A08CA8"
              fontWeight="semibold"
            >
              All categories
            </Text>
          </Flex>

          <Modal title="Add Category" buttonText="Add Category">
            <CreateCategoryForm
              updateTable={page =>
                getCategories(
                  setLoading,
                  userId,
                  setCategoriesData,
                  page,
                  DATA_ROWS.LIMIT
                )
              }
            />
          </Modal>
        </Flex>
        <ShopTable
          getCategories={categoriesData}
          updateTable={page =>
            getCategories(
              setLoading,
              userId,
              setCategoriesData,
              page,
              DATA_ROWS.LIMIT
            )
          }
        />
      </Box>
    </>
  )
}
