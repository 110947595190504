import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Login } from '../pages/login';
import { PROTECTED_PATHS } from './constants';

export default function UnAuthenticatedApp() {
  const { LOGIN } = PROTECTED_PATHS;
  return (
    <ChakraProvider>
      <Switch>
        <Route path={LOGIN} exact component={Login} />
      </Switch>

      <Redirect from='/*' to={LOGIN} />
    </ChakraProvider>
  );
}
