import { PaginatedTable } from "../../../components/PaginatedTable";
import { OngoingTableBody } from "./table-data/TableBody";
import { OngoingTableHead } from "./table-data/TableHead";

export const OngoingTable = ({ errand, updateTable, refresh }) => {
  return (
    <PaginatedTable
      columns={OngoingTableHead}
      data={<OngoingTableBody errand={errand} updateTable={() => refresh()} />}
      length={errand?.length}
      updateTable={(page) => updateTable(page)}
    />
  );
};
