import { DATA_ROWS } from '../../../app/constants';
import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

//ADD FUNDS TO WALLET
export const addFunds = async (payload, setLoading, onClose) => {
  try {
    await http.post(AUTH_ROUTES.ADD_FUNDS, payload);
    setLoading(false);
    successNotifier('Funds added successfully');
    onClose();
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.message);
  }
};

//GET ALL TRANSACTIONS FROM THE DATABASE
export const getTransactions = async (setLoading, userId, setTransactions, skip = 0, limit = 20) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_TRANSACTIONS(userId, skip, limit));
    setTransactions(data.transactions);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result);
    setLoading(false);
  }
};

export const getCreditTransactions = async (
  setLoading,
  userId,
  setCreditTransactions,
  skip = 0,
  limit = DATA_ROWS.LIMIT,
  transactionType = 'Credit'
) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_TRANSACTIONS(userId, page, limit, transactionType));
    setCreditTransactions(data.transactions);

    setLoading(false);
  } catch (e) {
    //   errorNotifier(e.response.data.result);
    setCreditTransactions([]);
    setLoading(false);
  }
};

export const getTransferTransactions = async (
  setLoading,
  userId,
  setTransferTransactions,
  skip = 0,
  limit = DATA_ROWS.LIMIT,
  transactionType = 'Transfer'
) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_TRANSACTIONS(userId, page, limit, transactionType));
    setTransferTransactions(data.transactions);
    setLoading(false);
  } catch (e) {
    //   errorNotifier(e.response.data.result);
    setTransferTransactions([]);
    setLoading(false);
  }
};

export const getDebitTransactions = async (
  setLoading,
  userId,
  setDebitTransactions,
  skip = 0,
  limit = DATA_ROWS.LIMIT,
  transactionType = 'Debit'
) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_TRANSACTIONS(userId, page, limit, transactionType));
    setDebitTransactions(data.transactions);
    setLoading(false);
  } catch (e) {
    //   errorNotifier(e.response.data.result);
    setDebitTransactions([]);
    setLoading(false);
  }
};

// Get ALL FUND WALLET
export const getFundWallet = async (setLoading, userId, setFundWallet, skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES?.GET_FUNDWALLET(userId, page, limit));
    setFundWallet(data?.funds);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e?.response?.data?.result);
    setFundWallet([]);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

// GET ANALYTICS FOR FINANCE
export const getAnalytics = async (setLoading, userId, setAnalytics) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_DASHBOARD(userId));

    setAnalytics(data);
  } catch (e) {
    // errorNotifier(e?.response?.data?.result);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
