import { Button } from "@chakra-ui/button"
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input"
import { Box, Flex, Text, VStack } from "@chakra-ui/layout"
import { useState } from "react"
import { FiEye, FiEyeOff } from "react-icons/fi"
import { loginUser } from "../service/loginUser"

export const LoginForm = () => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleClick = () => setShow(!show)

  const handleLogin = async () => {
    setLoading(true)
    await loginUser({ email, password }, setLoading)
    setLoading(false)
  }

  const NO_SHADOW = { _focus: { boxShadow: "none" } }
  const BTN_STYLE = { _hover: { bg: "#3e2659" }, bg: "#614285", color: "#fff" }
  const SM_SIZE = { fontSize: ".8em" }
  const OUTLINE_COLOR = { _focus: { outline: "1px solid #614285" } }
  return (
    <>
      <VStack spacing="40px" alignItems="normal">
        <Box>
          <Text {...SM_SIZE} margin="10px 0">
            Email Address
          </Text>
          <InputGroup>
            <Input
              type="email"
              {...OUTLINE_COLOR}
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter email address"
            />
          </InputGroup>
        </Box>

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Text {...SM_SIZE} margin="10px 0">
              Password
            </Text>
            {/* <Text color='#614285' cursor='pointer'>
              <small>Forgot password?</small>
            </Text> */}
          </Flex>

          <InputGroup size="md">
            <Input
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="Enter password"
              onChange={e => setPassword(e.target.value)}
              {...OUTLINE_COLOR}
            />
            <InputRightElement width="4.5rem">
              <Button
                {...NO_SHADOW}
                _hover={{ bg: "transparent" }}
                bg="transparent"
                h="1.75rem"
                size="sm"
                onClick={handleClick}
              >
                {show ? <FiEyeOff /> : <FiEye />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
        <Button
          isLoading={loading}
          {...NO_SHADOW}
          onClick={handleLogin}
          {...BTN_STYLE}
        >
          Login
        </Button>
      </VStack>
    </>
  )
}
