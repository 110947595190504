import { PaginatedTable } from "../../../components/PaginatedTable";
import { SubscriptionTableBody } from "./SubscriptionTableBody";
import { SubscriptionTableHead } from "./SubscriptionTableHead";

export const SubscriptionTable = ({ getSubscription, updateTable }) => {
  return (
    <PaginatedTable
      columns={SubscriptionTableHead}
      data={
        <SubscriptionTableBody
          getSubscription={getSubscription}
          updateTable={() => updateTable()}
        />
      }
      length={getSubscription?.length}
      updateTable={(page) => updateTable(page)}
    />
  );
};
