import { useState, useRef, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Flex,
  Center,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { AiFillPlusCircle } from 'react-icons/ai';
import { createNewOffer } from '../pages/offers/service/offer';
import { errorNotifier } from './NotificationHandler';
import axios from 'axios';

const CreateAnOfferForm = ({ onClose, updateTable }) => {
  const getUserId = useSelector((state) => state.auth.user);
  const [userId] = useState(getUserId);
  const initialValues = {
    itemName: '',
    itemDescription: '',
    quantity: 3,
    price: '',
    offerImage: '',
    initialSlots: '',
    userId,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const formRef = useRef();

  useEffect(() => {
    const { initialSlots, itemDescription, itemName, price, quantity } =
      formValues;

    initialSlots && itemDescription && itemName && price && quantity
      ? setDisable(false)
      : setDisable(true);
  }, [formValues]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormValues({ ...formValues, [name]: value, status: 'Active' });
  };
  let imgUrlsFromCloudinary = [];

  const handleFileChange = (e) => {
    e.preventDefault();
    if (!e.target.files) return;
    if (Array(...e.target.files).length > 5) {
      errorNotifier('Pick a maximum of five Images');
      return;
    }

    Array(...e.target.files).forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setImages((prev) => [...prev, { imageUrl: reader.result, file }]);
      };
    });
  };

  const handleSubmit = (e, files) => {
    e.preventDefault();

    if (!files.length) {
      errorNotifier('Add Images for the Offer');
      return;
    }

    const uploaders = files.map((file) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('upload_preset', 'zealight');

      return axios
        .post(
          'https://api.cloudinary.com/v1_1/zillight/image/upload',
          formData,
          {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          }
        )
        .then((response) => {
          const data = response.data;
          const fileURL = data.secure_url; // You should store this URL for future references in your app

          imgUrlsFromCloudinary.push(fileURL);
        });
    });

    axios.all(uploaders).then(() => {
      const payload = { ...formValues, imageUrl: imgUrlsFromCloudinary };

      createNewOffer(payload, setLoading, onClose).then(() => updateTable());
    });
  };

  return (
    <FormControl>
      <form
        ref={formRef}
        id='createAnOffer'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <FormLabel>Name of item</FormLabel>
        <Input
          onChange={handleChange}
          mb={2}
          type='text'
          name='itemName'
          value={formValues.itemName}
          variant='filled'
          required
        />
        <Flex justifyContent='space-between'>
          <div style={{ flexBasis: '48%' }}>
            <FormLabel>Amount</FormLabel>
            <Input
              onChange={handleChange}
              type='number'
              name='price'
              value={formValues.price}
              mb={2}
              variant='filled'
              required
            />
          </div>
          <div style={{ flexBasis: '48%' }}>
            <FormLabel>Slot</FormLabel>
            <Input
              onChange={handleChange}
              type='number'
              name='initialSlots'
              value={formValues.initialSlots}
              mb={2}
              variant='filled'
              required
            />
          </div>
        </Flex>
        <Flex alignItems='center'>
          <FormLabel mb={0}>Add Photo</FormLabel>
          {/* <Text fontSize='xs'>you can add up to 5 photos</Text> */}
        </Flex>
        <Flex alignItems='center' mb={2}>
          <Flex p={2}>
            {images.length > 0 &&
              images.map((image, index) => {
                return (
                  <Center
                    key={index}
                    bg='gray.200'
                    h={12}
                    w={12}
                    borderRadius='md'
                    _notLast={{ marginRight: '.5rem' }}
                    overflow='hidden'
                  >
                    <img
                      src={image.imageUrl}
                      alt={image.name}
                      style={{ minWidth: '100%' }}
                    />
                  </Center>
                );
              })}
          </Flex>
          <Button
            role='button'
            bg='transparent'
            p={0}
            _focus={{ boxShadow: 'none', backgroundColor: 'transparent' }}
            _hover={{ background: 'transparent' }}
          >
            <FormLabel htmlFor='input-file' mb={0} mr={0}>
              <AiFillPlusCircle
                color='#FF897A'
                style={{ cursor: 'pointer' }}
                fontSize='1.5rem'
              />
            </FormLabel>
          </Button>
          <Input
            style={{ display: 'none' }}
            type='file'
            name='offerImage'
            id='input-file'
            accept='image/*'
            onChange={handleFileChange}
            multiple
          />
        </Flex>
        <FormLabel>Description</FormLabel>
        <Textarea
          onChange={handleChange}
          type='text'
          name='itemDescription'
          value={formValues.itemDescription}
          mb={4}
          variant='filled'
          h={10}
          required
        />
        <Button
          isLoading={loading}
          bg='#8366A5'
          _hover={{ bg: '#8366A5' }}
          _focus={{ boxShadow: 'none' }}
          color='#fff'
          h={50}
          fontWeight='medium'
          isDisabled={disable}
          onClick={(e) => handleSubmit(e, images)}
          type='submit'
        >
          Create Offer
        </Button>
      </form>
    </FormControl>
  );
};

export default CreateAnOfferForm;
