import http, { AUTH_ROUTES } from '../../../services/api';
import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';

export const changePassword = async (userId, payload, setLoading) => {
  try {
    await http.put(AUTH_ROUTES.CHANGE_PASSWORD(userId), payload);
    setLoading(false);
    successNotifier('Changed password successfully');
  } catch (e) {
    errorNotifier(e.response?.data?.result);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
