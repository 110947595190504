import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { warningNotifier } from '../../../components/NotificationHandler';
import { updateCategory } from '../service/shopApi';

const EditCategoryForm = ({ onClose, updateTable, initialValues }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const handleUpdate = (e) => {
    e.preventDefault();
    const { categoryName, description } = formValues;

    if (!categoryName) {
      warningNotifier('Please enter the category name');
      setLoading(false);
      return;
    }
    if (!description) {
      warningNotifier('Please the category description');
      setLoading(false);
      return;
    }
    setLoading(true);
    const payload = {
      ...formValues,
      userId,
    };
    updateCategory(payload, setLoading, onClose).then(() => updateTable());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  return (
    <FormControl id='createCategories' display='flex' flexDirection='column'>
      <FormLabel>Name</FormLabel>
      <Input
        name='categoryName'
        value={formValues.categoryName}
        onChange={handleChange}
        mb={4}
        variant='filled'
        type='text'
      />
      <FormLabel>Description</FormLabel>
      <Textarea
        name='description'
        value={formValues.description}
        onChange={handleChange}
        mb={8}
        variant='filled'
        h={40}
      />
      <Button
        isLoading={loading}
        bg='#8366A5'
        _hover={{ bg: '#8366A5' }}
        _focus={{ boxShadow: 'none' }}
        color='#fff'
        h={50}
        fontWeight='medium'
        onClick={handleUpdate}
      >
        Update
      </Button>
    </FormControl>
  );
};

export default EditCategoryForm;
