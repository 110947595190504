import React from 'react';

import { Flex, Box, Text, Image, Spacer } from '@chakra-ui/react';

const DashboardCard = ({ title, amount, img, bgColor, alt, marginRight }) => {
  return (
    <Box
      // w={['90%', '90%', '40%', '25%']}
      bg={bgColor}
      borderRadius='10'
      p={4}
      pb={1}
      pr={7}
      ml={15}
      mr={15}
      mb={15}
    >
      <Text textAlign='left' fontSize='18'>
        {title}
      </Text>
      <Flex>
        <Box>
          <Text fontSize='46' fontWeight='400'>
            {amount}
          </Text>
        </Box>
        <Spacer />
        <Box boxSize='10' mt={4}>
         { img && <Image src={img} alt={alt} />}
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardCard;
