import dayjs from "dayjs";
import { nanoid } from "@reduxjs/toolkit";
import { Td, Tr } from "@chakra-ui/table";

export const SubscriptionTableBody = ({ getSubscription }) => {
  return (
    <>
      {getSubscription &&
        getSubscription.map((x) => {
          return (
            <Tr key={nanoid()}>
              <Td>{x?.userId?.tenantName}</Td>
              <Td>{`₦ ${parseInt(x?.amount).toLocaleString()}`}</Td>
              <Td>{x?.userId?.email}</Td>
              <Td>{x.userId?.tenantPhone}</Td>
              <Td>{dayjs(x?.startDate).format("DD-MMM-YYYY")}</Td>
              <Td>{dayjs(x?.endDate).format("DD-MMM-YYYY")}</Td>
            </Tr>
          );
        })}
    </>
  );
};
