import { errorNotifier } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const banErrandBoy = async (payload) => {
  try {
    await http.put(
      AUTH_ROUTES.BAN_ERRAND_BOY(payload.userId, payload._id),
      payload
    );
  } catch (e) {
    errorNotifier(e.response?.data?.result);
  }
};
