import { Box, Flex } from '@chakra-ui/layout';
import { CreateManager } from './CreateManager';
import { TableHead } from './table-data/TableHead';
import { BannedTableBody } from './table-data/TableBody';
import { Modal } from '../../modal';
import { PaginatedTable } from '../../../components/PaginatedTable';

export const BannedTable = ({ getData = [], updateTable, title, refresh }) => {
  return (
    <Box padding='10px' bg='#fff'>
      <Flex justifyContent='flex-end' margin='10px 0'>
        <Modal buttonText={title} title='Create manager'>
          <CreateManager updateTable={() => refresh()} />
        </Modal>
      </Flex>

      <PaginatedTable
        columns={TableHead}
        data={
          <BannedTableBody
            getManagers={getData}
            updateTable={() => refresh()}
          />
        }
        length={getData?.length}
        updateTable={(page) => updateTable(page)}
      />
    </Box>
  );
};
