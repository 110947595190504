import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const createNewSecurity = async (payload, setLoading, setFormValues, initialValues, onClose) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_SECURITY, payload);

    setLoading(false);
    setFormValues(initialValues);
    successNotifier('Successfully created a Security');
    onClose();
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.result);
  }
};
