import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";
import { ActiveTable } from "../ActiveTable";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BannedTable } from "../BannedTable";
import { nanoid } from "@reduxjs/toolkit";
import {
  getActiveManagers,
  getBannedManagers,
} from "../../service/getManagers";
import FullPageLoader from "../../../../components/full-page-loader";

export const ManagerPanel = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [activeManagers, setActiveManagers] = useState([]);
  const [bannedManagers, setBannedManagers] = useState([]);
  const [refresh, setRefresh] = useState({});

  const NO_PADDING = { padding: "none" };
  const PD = { padding: ["10px", "10px", "20px"] };

  useEffect(() => {
    getActiveManagers(setLoading, userId, setActiveManagers);
    getBannedManagers(setLoading, userId, setBannedManagers);
  }, [refresh, userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Tabs color="grey" colorScheme="yellow">
      <TabList>
        <Tab
          key={nanoid()}
          outline="none !important"
          _focus={{ boxShadow: "none" }}
          w="180px"
          _selected={{
            color: "#26D637",
            borderBottom: "4px solid #26D637",
            borderBottomRadius: "3px",
          }}
        >
          Active Managers
        </Tab>
        <Tab
          key={nanoid()}
          outline="none !important"
          _focus={{ boxShadow: "none" }}
          w="180px"
          _selected={{
            color: "#D6B738",
            borderBottom: "4px solid #D6B738",
            borderBottomRadius: "3px",
          }}
        >
          Banned Managers
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel {...NO_PADDING}>
          <Box bg="#fff" {...PD}>
            <ActiveTable
              getData={activeManagers}
              title="Create Manager"
              refresh={() => setRefresh({})}
              updateTable={(page) =>
                getActiveManagers(setLoading, userId, setActiveManagers, page)
              }
            />
          </Box>
        </TabPanel>
        <TabPanel {...NO_PADDING}>
          <Box bg="#fff" {...PD}>
            <BannedTable
              getData={bannedManagers}
              title="Create Manager"
              refresh={() => setRefresh({})}
              updateTable={(page) =>
                getBannedManagers(setLoading, userId, setBannedManagers, page)
              }
            />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
