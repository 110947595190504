import { Box, Divider, Text, HStack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalTable } from '../../withdrawal/components/global-table-template';
import { syncDataPlans } from '../service';
import { CableSubscriptionTableBody } from './table-data/TableBody';
import { CableSubscriptionTableHead } from './table-data/TableHead';

export const CableSubscription = ({ data }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  return (
    <Box
      m='10px 0'
      p={['10px', '20px']}
      bg='#fff'
      boxShadow='md'
      borderRadius='5px'
    >
      <HStack marginBottom='10px' justify='space-between'>
        <Text color='#8366A5'>
          <b>Cable Subscription</b>
        </Text>
        <Button
          isLoading={loading}
          onClick={() => syncDataPlans(userId, setLoading)}
          color='#FF6F6F'
          variant='outline'
          size='xs'
        >
          Sync prices
        </Button>
      </HStack>
      <Divider />
      <Box h='300px' overflow='auto'>
        <GlobalTable
          tableHead={<CableSubscriptionTableHead />}
          tableBody={<CableSubscriptionTableBody Data={data} />}
          striped={false}
        />
      </Box>
    </Box>
  );
};
