import React, { useEffect, useState } from "react"
import { Flex, Box, Text } from "@chakra-ui/react"
import NotificationCard from "./components/NotificationCard"
import { getNotifications } from "./service/getNotifications"

const NotificationPage = () => {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    getNotifications(setNotifications)
  }, [])

  return (
    <Flex mt="30px">
      <Box
        w={["90%", "90%", "95", "98%"]}
        h={["100vh", "100vh", "90vh", "80vh"]}
      >
        <Box color="white">
          {notifications?.length > 0 ? (
            notifications?.map((content, key) => {
              return (
                <NotificationCard
                  key={key}
                  bgColor={content?.background}
                  title={content?.title}
                  description={content?.body}
                  img={content?.image}
                  amount={content?.value}
                  alt={content?.title}
                />
              )
            })
          ) : (
            <Text fontSize="1.5em" color="#686868" textAlign="center">
              No notifications available
            </Text>
          )}
        </Box>
      </Box>
    </Flex>
  )
}

export default NotificationPage
