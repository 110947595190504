import { Box, Flex } from "@chakra-ui/layout";
import { CreateSecurity } from "./CreateSecurity";
import { TableHead } from "./security-table-data/TableHead";
import { BannedTableBody } from "./security-table-data/TableBody";
import { Modal } from "../../modal";
import { PaginatedTable } from "../../../components/PaginatedTable";

export const BannedTable = ({ getData = [], updateTable, refresh, title }) => {
  return (
    <Box padding="10px" bg="#fff">
      <Flex justifyContent="flex-end" margin="10px 0">
        <Modal buttonText={title} title="Create Security">
          <CreateSecurity updateTable={() => refresh()} />
        </Modal>
      </Flex>

      <PaginatedTable
        columns={TableHead}
        data={
          <BannedTableBody
            getSecurities={getData}
            updateTable={() => refresh()}
          />
        }
        length={getData?.length}
        updateTable={(page) => updateTable(page)}
      />
    </Box>
  );
};
