import { Tr, Td, Tooltip, Text } from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";

export const TableBody = ({ errands }) => {
  const DESKTOP = { fontSize: ".7em" };
  const TIP = { border: "1px solid #AEAEAE", bg: "white", color: "black" };

  return (
    <>
      {errands.map((errand, index) => (
        <Tr bg="#fff" {...DESKTOP} key={nanoid()}>
          <Td>{index + 1}</Td>
          <Td>{errand.errandCategory}</Td>
          <Td>{errand.houses.estate[0].unitNumber}</Td>
          <Td>{errand.numberOfErrands}</Td>
          <Td>
            <Text className="truncate">
              <Tooltip label={" Buying gas and delivering"} {...TIP}>
                {errand.errandDescription}
              </Tooltip>
            </Text>
          </Td>
        </Tr>
      ))}
    </>
  );
};
