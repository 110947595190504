import { PaginatedTable } from "../../../components/PaginatedTable";
import { CompletedTableBody } from "./table-data/TableBody";
import { CompletedTableHead } from "./table-data/TableHead";

export const CompletedTable = ({ errand, updateTable, refresh }) => {
  return (
    <PaginatedTable
      columns={CompletedTableHead}
      data={
        <CompletedTableBody errand={errand} updateTable={() => refresh()} />
      }
      length={errand?.length}
      updateTable={(page) => updateTable(page)}
    />
  );
};
