import { Tr, Td } from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";

export const TableBodyMobile = ({ dataArray = [] }) => {
  const MOBILE = { fontSize: ".7em" };
  const PD = { pt: "8px", pb: "8px" };
  return (
    <>
      {dataArray &&
        dataArray.map((x) => (
          <Tr bg="#fff" {...MOBILE} key={nanoid()}>
            <Td {...PD}>{x.itemName}</Td>
            <Td {...PD}>{x.category} </Td>
            <Td {...PD}>{x.totalView}</Td>
          </Tr>
        ))}
    </>
  );
};
