import React, { useState } from "react";
import { FormControl } from "@chakra-ui/form-control";
import { Box, Flex, Heading } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { useSelector } from "react-redux";
import { createNewManager } from "../service/createNewManager";

export const CreateManager = ({ onClose, updateTable }) => {
  const getUserId = useSelector((state) => state.auth.user);
  const [userId] = useState(getUserId);
  const initialValues = {
    estateName: "",
    estateAddress: "",
    phoneNumber: "",
    email: "",
    password: "",
    userId,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const INPUT_STYLE = {
    mb: "22px",
    border: "0.6px solid #656363",
    borderRadius: "5px",
    bg: "#F1F1F1",
  };
  const BTN_STYLE = {
    color: "#fff",
    bg: "#FF897A",
    fontSize: "12px",
    fontWeight: "400",
    _hover: { bg: "#FF897A" },
    mt: 6,
    w: "100%",
    borderRadius: "5px",
    _focus: { borderRadius: "none" },
  };
  const LABEL_STYLE = { mb: "3px", fontSize: "12px" };
  const FLEX_STYLE = { bg: "#FFFFFF", borderRadius: "5px" };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    createNewManager(
      formValues,
      setLoading,
      setFormValues,
      initialValues,
      onClose
    ).then(() => updateTable());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <>
      <Box color="#8366A5" fontSize="12px">
        <Heading fontSize="md" mb={5}>
          Create Manager
        </Heading>
        <Flex {...FLEX_STYLE}>
          <FormControl as="form" onSubmit={handleSubmit} isRequired>
            <FormLabel {...LABEL_STYLE}>Estate Name</FormLabel>
            <Input
              type="text"
              {...INPUT_STYLE}
              name="estateName"
              id="estateName"
              value={formValues.estateName}
              onChange={handleChange}
            />

            <FormLabel {...LABEL_STYLE}>Estate Address</FormLabel>
            <Input
              type="text"
              {...INPUT_STYLE}
              name="estateAddress"
              id="estateAddress"
              value={formValues.estateAddress}
              onChange={handleChange}
            />

            <FormLabel {...LABEL_STYLE}>Phone Number</FormLabel>
            <Input
              type="tel"
              {...INPUT_STYLE}
              name="phoneNumber"
              id="phonenumber"
              value={formValues.phoneNumber}
              onChange={handleChange}
            />

            <FormLabel {...LABEL_STYLE}>Email</FormLabel>
            <Input
              type="email"
              {...INPUT_STYLE}
              name="email"
              id="email"
              value={formValues.email}
              onChange={handleChange}
            />

            <FormLabel {...LABEL_STYLE}>Password</FormLabel>
            <Input
              type="password"
              name="password"
              id="password"
              {...INPUT_STYLE}
              value={formValues.password}
              onChange={handleChange}
            />

            <Button isLoading={loading} type="Submit" {...BTN_STYLE}>
              Submit
            </Button>
          </FormControl>
        </Flex>
      </Box>
    </>
  );
};
