import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getCardData = async (userId, setCardData) => {
  const result = await http.get(AUTH_ROUTES.GET_DASHBOARD(userId));
  const {
    result: {
      data: { totalActiveOffers, totalOffers, totalProducts, totalOfferView },
    },
  } = result.data;
  setCardData({
    totalActiveOffers,
    totalOffers,
    totalProducts,
    totalOfferView,
  });
};

export const getOffers = async (setLoading, userId, setOffers) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.get(AUTH_ROUTES.GET_OFFERS(userId));
    setOffers(result?.data);

    setLoading(false);
  } catch (e) {
    setLoading(false);
    setOffers({ offers: [] });

    // errorNotifier(e.response?.data?.result?.message);
  }
};

export const getActiveOffers = async (setLoading, userId, setActiveOffers, status = 'Active') => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_OFFERS(userId, status));
    setActiveOffers(data.offers);

    setLoading(false);
  } catch (e) {
    setLoading(false);
    setActiveOffers([]);

    // errorNotifier(e.response?.data?.result?.message);
  }
};

export const getCompletedOffers = async (setLoading, userId, setCompletedOffers, status = 'Completed') => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_OFFERS(userId, status));
    setCompletedOffers(data.offers);

    setLoading(false);
  } catch (e) {
    setCompletedOffers([]);
    setLoading(false);
  }
};

export const getCanceledOffers = async (setLoading, userId, setCanceledOffers, status = 'Canceled') => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_OFFERS(userId, status));
    setCanceledOffers(data.offers);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    setCanceledOffers([]);

    // errorNotifier(e.response?.data?.result?.message);
  }
};

export const getOffer = async (setLoading, userId, offerId, setOffer, initialValues) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.get(AUTH_ROUTES.GET_OFFER(userId, offerId));
    const { _id, updatedAt, __v, imageUrl, createdAt, ...formData } = result.data.offers[0];
    setOffer({ ...initialValues, ...formData });
    setLoading(false);
  } catch (e) {
    setLoading(false);
    // errorNotifier(e.response?.data?.result?.message);
  }
};

export const getTopOffers = async (setLoading, userId, setTopOffer) => {
  try {
    const {
      data: { result },
    } = await http.get(AUTH_ROUTES.GET_TOP_OFFERS(userId));
    setTopOffer(result.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    if (e.response?.data?.result?.message === 'No Offer available') setTopOffer([]);
  }
};

export const createNewOffer = async (payload, setLoading, onClose) => {
  setLoading(true);
  try {
    await http.post(AUTH_ROUTES.CREATE_OFFER, payload);

    setLoading(false);
    successNotifier('Successfully created new offer');
    onClose();
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.errors[0].msg);
  }
};

export const deleteOffer = async (adminId, rowId, refresh) => {
  try {
    const {
      data: { result },
    } = await http.delete(AUTH_ROUTES.DELETE_OFFER(adminId, rowId));

    successNotifier(result?.message);
    refresh();
  } catch (e) {
    errorNotifier(e.response?.data?.result?.message);
  } finally {
  }
};

export const editOffer = async (adminId, rowId, formData, setLoading = () => null, setRefresh = () => null) => {
  setLoading(true);
  try {
    await http.put(AUTH_ROUTES.EDIT_OFFER(adminId, rowId), formData);

    successNotifier('Successfully edited offer');
    setRefresh();
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.result?.message);
  }
};

export const getOfferParticipants = async (setLoading, userId, rowId, setParticipants) => {
  try {
    const {
      data: { result },
    } = await http.get(AUTH_ROUTES.GET_OFFER_PARTICIPANTS(userId, rowId));
    setParticipants(result.data?.participants);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result?.message);
    setLoading(false);
  }
};

export const completeOffer = async (setBtnLoading, payload, onClose) => {
  try {
    await http.put(AUTH_ROUTES.EDIT_OFFER(payload.userId, payload.offerId), payload);
    setBtnLoading(false);
    successNotifier('Successfully completed offer');
    onClose();
  } catch (e) {
    setBtnLoading(false);
    errorNotifier(e.response?.data?.result?.message);
  }
};

export const cancelOffer = async (userId, payload) => {
  try {
    await http.put(AUTH_ROUTES.EDIT_OFFER(userId, payload._id), payload);
    successNotifier('Successfully canceled offer');
  } catch (e) {
    errorNotifier(e.response?.data?.message);
  }
};

export const sendMessageToParticipants = async (payload, setLoading) => {
  try {
    setLoading(true);
    await http.post(AUTH_ROUTES.SEND_MESSAGE_TO_PARTICIPANTS, payload);
    successNotifier('Message successfully sent');
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};
