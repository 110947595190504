import { Tr, Td } from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";

export const TableBody = ({ offers }) => {
  const DESKTOP = { fontSize: ".7em" };
  return (
    <>
      {offers?.length > 0
        ? offers.map((offer, index) => (
            <Tr bg="#fff" {...DESKTOP} key={nanoid()}>
              <Td>{index + 1}</Td>
              <Td>not available</Td>
              <Td>{offer.category}</Td>
              <Td>not available</Td>
              <Td>{offer.itemDescription}</Td>
            </Tr>
          ))
        : null}
    </>
  );
};
