import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Text,
  HStack,
  Box,
} from "@chakra-ui/react";

import { addFunds } from "../pages/finance/service/financeApi";
import { getTenantProfile } from "../pages/finance/service/getTenantProfile";

const AddFundToWalletForm = ({
  onClose,
  updateTable,
}) => {
  const getUserId = useSelector((state) => state.auth.user);
  const [userId] = useState(getUserId);

  const textStyles = {
    backgroundColor: "#f2f2f2",
    height: "30px",
    borderRadius: "5px",
    width: "100%",
    marginBottom: "10px",
    paddingLeft: "10px",
  };

  const initialValues = {
    userName: "",
    tenantPhone: "",
    email: "",
    amount: "",
    description: "",
    userId,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [tenantProfile, setTenantProfile] = useState([]);


  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    addFunds(formValues, setLoading, onClose).then(() => updateTable());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleGetDetails = (phone) => {
    setCheckLoading(true)
    getTenantProfile(setCheckLoading, userId, phone, setTenantProfile);
  };

  return (
    <FormControl
      as="form"
      id="createCategories"
      display="flex"
      flexDirection="column"
      isRequired
      onSubmit={handleSubmit}
    >
      <FormLabel id="name">Phone Number</FormLabel>
      <HStack alignItems="baseline">
        <Box>
          <Input
            mb={{ base: 2, md: 0, lg: 2 }}
            h={{ base: "auto", md: 8 }}
            variant="filled"
            type="tel"
            name="tenantPhone"
            value={formValues?.tenantPhone}
            onChange={handleChange}
          />
        </Box>
        <Button
        size="sm"
          isLoading={checkLoading}
          
          onClick={() => handleGetDetails(formValues?.tenantPhone)}
        >
          Check
        </Button>
      </HStack>

      <FormLabel id="name">Name</FormLabel>
      <Text style={textStyles}>{tenantProfile?.tenantName}</Text>
      <FormLabel id="name">Email</FormLabel>
      <Text style={textStyles}>{tenantProfile?.email}</Text>
      <FormLabel id="name">Amount</FormLabel>
      <Input
        mb={{ base: 2, md: 0, lg: 2 }}
        h={{ base: "auto", md: 8 }}
        variant="filled"
        type="number"
        name="amount"
        value={formValues?.amount}
        onChange={handleChange}
      />

      <FormLabel id="name">Description</FormLabel>
      <Textarea
        mb={{ base: 4, md: 4 }}
        variant="filled"
        h={{ base: "auto", md: 5 }}
        name="description"
        value={formValues?.description}
        onChange={handleChange}
      />
      <Button
        isLoading={loading}
        type="Submit"
        bg="#8366A5"
        color="#fff"
        h={{ base: 10 }}
        fontWeight="medium"
        _hover={{ bg: "#8366A5" }}
      >
        Submit
      </Button>
    </FormControl>
  );
};

export default AddFundToWalletForm;
