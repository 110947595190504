import { useState, useEffect, useRef } from 'react';
import { FormControl, FormLabel, Input, Textarea, Button, Text, HStack, Circle, Img } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { editOffer } from '../pages/offers/service/offer';
import { errorNotifier } from './NotificationHandler';
import { nanoid } from '@reduxjs/toolkit';
import { BsPlusLg } from 'react-icons/bs';
import axios from 'axios';

const EditAnOfferForm = ({ onClose, setRefresh, rowId }) => {
  const getUserId = useSelector((state) => state.auth.user);
  const [userId] = useState(getUserId);
  const [formValues, setFormValues] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const formRef = useRef();
  const [userChangedImage, setUserChangedImage] = useState([]);
  let imgUrlsFromCloudinary = [];

  useEffect(() => {
    setFormValues(rowId);
  }, [rowId]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFileChange = (e) => {
    e.preventDefault();

    if (!e.target.files) return;

    if (Array(...e.target.files).length > 5) {
      errorNotifier('Pick a maximum of five Images');
      return;
    }

    if (e.target.files) {
      Array.from(e.target.files).forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          setUserChangedImage((prev) => [...prev, { imageUrl: reader.result, file }]);
        };
      });
    }
  };

  const submitWithNewImages = (e, files) => {
    e.preventDefault();
    setButtonLoading(true);

    const uploaders = files.map((file) => {
      const formData = new FormData();

      formData.append('file', file.file);
      formData.append('upload_preset', 'zealight');

      return axios
        .post(`https://api.cloudinary.com/v1_1/zillight/image/upload`, formData, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        .then((res) => {
          const data = res.data;

          const fileURL = data.secure_url;
          imgUrlsFromCloudinary.push(fileURL);
        });
    });
    axios.all(uploaders).then(() => {
      const payload = { ...formValues, imageUrl: imgUrlsFromCloudinary };

      editOffer(userId, formValues._id, payload, setButtonLoading, setRefresh);
    });
  };

  const editWithInitialImages = (e) => {
    e.preventDefault();

    editOffer(userId, formValues._id, formValues, setButtonLoading, setRefresh);
  };

  return (
    <FormControl>
      <form ref={formRef} id='createAnOffer' style={{ display: 'flex', flexDirection: 'column' }}>
        <FormLabel>Name of item</FormLabel>
        <Input onChange={handleChange} mb={2} type='text' name='itemName' value={formValues.itemName} variant='filled' required />

        <HStack py='5'>
          <label htmlFor='advert-picture'>
            <Text fontSize='12px' fontWeight='600' color='#111'>
              Add Photo(s)
            </Text>
            <Circle bg='#F09091' h='20px' w='20px' color='#fff'>
              <BsPlusLg size={10} />
              <Input type='file' accept='image/*' id='advert-picture' display='none' multiple onChange={handleFileChange} />
            </Circle>
          </label>
          {!userChangedImage.length
            ? rowId.imageUrl?.map((ad) => <Img mx='2' w='60px' h='60px' objectFit='cover' key={nanoid()} src={ad || ad.imageUrl} alt='jjj' />)
            : userChangedImage?.map((newImgs) => <Img mx='2' w='60px' h='60px' objectFit='cover' key={nanoid()} src={newImgs.imageUrl} alt='jjj' />)}
        </HStack>

        <FormLabel>Description</FormLabel>
        <Textarea
          onChange={handleChange}
          type='text'
          name='itemDescription'
          value={formValues.itemDescription}
          mb={4}
          variant='filled'
          h={10}
          required
        />
        <Button
          isLoading={buttonLoading}
          bg='#8366A5'
          _hover={{ bg: '#8366A5' }}
          _focus={{ border: 'none' }}
          color='#fff'
          onClick={userChangedImage.length ? (e) => submitWithNewImages(e, userChangedImage) : editWithInitialImages}
        >
          Update
        </Button>
      </form>
    </FormControl>
  );
};

export default EditAnOfferForm;
