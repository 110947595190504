import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';

import { BiTrash } from 'react-icons/bi';
import { BsThreeDots } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import CautionAlertDialog from '../../../../components/CautionAlertDialog';
import { CustomTable } from '../../../../components/customized table/CustomTable';
import { deleteOffer, editOffer } from '../../service/offer';

export const CanceledOffers = ({ canceledOffers, setRefresh }) => {
  const userId = useSelector((state) => state.auth.user);

  const canceledOfferColumns = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Item Name',
      accessor: 'itemName',
    },
    {
      Header: 'Amount',
      accessor: 'price',
    },
    {
      Header: 'Description',
      accessor: 'itemDescription',
    },
    {
      Header: 'Slots',
      accessor: 'initialSlots',
      Cell: ({ row }) => (
        <Text>{`${row.original.usedSlots} of ${row.original.initialSlots}`}</Text>
      ),
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => <Text>{dayjs(value).format('DD-MMM-YYYY')}</Text>,
    },
    {
      Header: 'Action',
      accessor: '_id',
      Cell: ({ row }) => (
        <>
          {' '}
          <HStack>
            <CautionAlertDialog
              btnTitle='Delete'
              mt='5px'
              icon={<BiTrash color='#fc8181' fontSize='1.3em' />}
              cautionTitle='Are you sure you want to permanently Delete this offer?'
              onContinue={() => handleDelete(row.original)}
            />

            <Tooltip label='reactivate'>
              <Box>
                <CautionAlertDialog
                  onContinue={() => handleReactivate(row.original)}
                  icon={<BsThreeDots fontSize='1.5em' />}
                  cautionTitle='Reactivate Offer?'
                />
              </Box>
            </Tooltip>
          </HStack>
        </>
      ),
    },
  ];

  const handleDelete = (data) => {
    deleteOffer(userId, data._id, setRefresh);
  };

  const handleReactivate = (offer) => {
    const payload = { participants: [], status: 'Active' };

    editOffer(userId, offer._id, payload, () => null).then(setRefresh);
  };
  return (
    <>
      <CustomTable COLUMNS={canceledOfferColumns} DATA={canceledOffers} />
    </>
  );
};
