import React from "react";
import { Td, Tr } from "@chakra-ui/react";
import { Modal } from "../../modal";
import AssignErrandBoyForm from "../../../components/AssignErrandBoyForm";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import { useSelector } from "react-redux";
import { cancelErrand } from "../service/homeApi";

const TableRow = ({ houseNo, type, errand, id, onRefresh }) => {
  const userId = useSelector((state) => state.auth.user);

  const handleCancelErrand = () => {
    const payload = {
      ...errand,
      status: "CANCELLED",
      userId,
    };
    cancelErrand(payload).then(() => onRefresh(true));
  };

  const FONT_SIZE = { fontSize: "12px" };

  return (
    <Tr borderBottom="10px solid #fff" bg="#FAF6FE">
      <Td {...FONT_SIZE}>{houseNo}</Td>
      <Td {...FONT_SIZE}>{type}</Td>
      <Td
        {...FONT_SIZE}
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
      >
        <Modal title="Assign" buttonText="Assign" small={true} bg="#4caa4c">
          <AssignErrandBoyForm
            onRefresh={() => onRefresh(true)}
            errandData={errand}
          />
        </Modal>

        <CautionAlertDialog
          btnTitle="Cancel"
          cautionTitle="Are you sure you want to cancel this errand?"
          mt="5px"
          size="xs"
          border="1px solid #dbdbdb"
          onContinue={handleCancelErrand}
        />
      </Td>
    </Tr>
  );
};

export default TableRow;
