import { Box, VStack, Text, Input, Flex, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaUpload } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { createBanner } from '../../service';

export const CreateBanner = ({ setRefresh }) => {
  const [pickedBannerImage, setPickedBannerImage] = useState({});
  const [description, setDescription] = useState('');
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const pickBanner = (e) => {
    if (!e.target.files) return;
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setPickedBannerImage({
        imageUrl: reader.result,
        file: e.target.files[0],
      });
    };
  };

  const uploadBanner = () => {
    const formData = new FormData();

    formData.append('userId', userId);
    formData.append('description', description);
    formData.append('imageUrl', pickedBannerImage.file);

    createBanner(formData, setLoading, setRefresh);
  };

  useEffect(() => {
    description && pickedBannerImage.file
      ? setDisabled(false)
      : setDisabled(true);
  }, [description, pickedBannerImage]);

  return (
    <Box>
      <VStack py='5'>
        <Input
          my='5'
          size='sm'
          w='300px'
          borderRadius='4px'
          _focus={{ borderColor: '#FF897A' }}
          border='1px solid #535353'
          bg='gray.100'
          onChange={(e) => setDescription(e.target.value)}
          placeholder='enter the offer or decription...'
        />
        <Flex
          justify='center'
          align='center'
          direction='column'
          borderRadius='5px'
          bg='gray.100'
          h='200px'
          w='300px'
          border='2px solid #ccc'
        >
          <label style={{ textAlign: 'center' }} htmlFor='picker'>
            <FaUpload cursor='pointer' fontSize={'3rem'} />
          </label>
          {pickedBannerImage.file && (
            <Text textAlign='center' py='2'>
              {pickedBannerImage.file.name}
            </Text>
          )}
          <Input
            type='file'
            id='picker'
            accept='image/*'
            display='none'
            onChange={pickBanner}
          />
        </Flex>
        <Box py='5'>
          <Button
            isLoading={loading}
            bg='#FF897A'
            isDisabled={disabled}
            _hover={{ bg: 'rgba(205, 107, 102, 1)' }}
            w='300px'
            onClick={uploadBanner}
            color='#fff'
          >
            Upload Banner
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};
