import http, { AUTH_ROUTES } from "../../../services/api";
import { DATA_ROWS } from "../../../app/constants";

export const getErrandTypes = async (
  setLoading,
  userId,
  setErrandTypes,
  skip = 0,
  limit = DATA_ROWS.LIMIT
) => {
  const page = limit * skip;
  const params = {
    userId,
    page,
    limit,
  };

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRANDS_TYPES(params));
    setErrandTypes(data.categories);
    setLoading(false);
  } catch (e) {
    setErrandTypes([]);
    setLoading(false);
  }
};
