import {
  Box,
  Grid,
  GridItem,
  Img,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { MdDeleteForever } from 'react-icons/md';
import { deleteBanner } from '../../service/index';
import { useSelector } from 'react-redux';
import CautionAlertDialog from '../../../../components/CautionAlertDialog';

const BannerSettings = ({ banners, setRefresh, setCurrentTab }) => {
  const userId = useSelector((state) => state.auth.user);
  const { onClose } = useDisclosure();

  const handleDelete = (banner) => {
    deleteBanner(userId, banner._id, setRefresh, onClose, setCurrentTab);
  };

  return (
    <Box>
      {/* <Flex justify='flex-end' py='5' color='white'>
        <Modal
          title={<Text fontSize='1.3rem'>Upload banner</Text>}
          buttonText='Create banner'
        >
          <CreateBanner setRefresh={() => setRefresh({})} />
        </Modal>
      </Flex> */}
      <Grid
        gap='3rem'
        bg='#fff'
        p='5'
        borderRadius='3px'
        minH='calc(100vh - 200px)'
        templateColumns={[
          'repeat(1,1fr)',
          'repeat(2,1fr)',
          'repeat(3,1fr)',
          'repeat(4,1fr)',
        ]}
      >
        {banners?.map((banner) => (
          <GridItem mb='10' key={nanoid()} className='banner-container'>
            <Img
              w='100%'
              h='100%'
              borderRadius='3px'
              shadow='md'
              src={banner?.imageUrl}
              alt='banner'
            />
            <Text
              fontWeight='600'
              fontSize='1.2rem'
              textDecoration='underline'
              textAlign='center'
              py='2'
            >
              {banner?.description}
            </Text>

            <Box className='banner-delete-icon'>
              <Text color='white'>{banner.description}</Text>

              <CautionAlertDialog
                cautionTitle='  Are you sure you want to delete this Banner image?'
                onContinue={() => handleDelete(banner)}
                icon={<MdDeleteForever color='crimson' size={35} />}
              />
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default BannerSettings;
