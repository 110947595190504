import { NavLink } from "react-router-dom";
import { Flex, useMediaQuery } from "@chakra-ui/react";

export const LinkTo = ({
  type,
  to,
  classname,
  iconComponent = null,
  btn,
  title,
  toggle,
}) => {
  const [isMobileScreen] = useMediaQuery("(max-width: 600px)");
  const handleLogout = () => {
    localStorage.removeItem("11#221#");
    window.location.replace(`/${type}/login`);
  };
  return btn ? (
    <Flex
      _hover={{
        cursor: "pointer",
        background: "#f2f2f2",
        borderTopLeftRadius: 40,
        fontWeight: "700",
        color: "black",
        borderBottomLeftRadius: 40,
        flex: " 1 0 auto",
      }}
      fontWeight="700"
      onClick={handleLogout}
      className={`${classname} link`}
    >
      {iconComponent && iconComponent}
      {title}
    </Flex>
  ) : (
    <NavLink
      activeClassName="active"
      className={`${classname} link`}
      onClick={() => (isMobileScreen ? toggle() : null)}
      target={title === "Guest CheckIn" ? "_blank" : ""}
      to={to ? to : "/"}
    >
      {iconComponent && iconComponent}
      {title}
    </NavLink>
  );
};
