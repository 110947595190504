import { Tr, Td} from "@chakra-ui/react";

export const TableBodyMobile = () => {
    const SM_PADDING = {padding: "8px 10px !important"}
    const SM = { fontSize: ".66em"};
    return (
        <>
        {[...Array(6)].map((x, i) =>
            <Tr bg="#fff" {...SM} key={i}>
                <Td {...SM_PADDING}>01</Td>
                <Td {...SM_PADDING}>Buying gas</Td>
                <Td {...SM_PADDING}>25 unit</Td>
                <Td {...SM_PADDING}>1202</Td>
            </Tr>
        )}
    </>
    )
}