import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { BsThreeDots, BsTrash } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import CautionAlertDialog from '../../../../components/CautionAlertDialog';
import { CustomTable } from '../../../../components/customized table/CustomTable';
import EditAnOfferForm from '../../../../components/editAnOfferForm';
import { Modal } from '../../../modal';
import { cancelOffer } from '../../service/offer';
import { OfferSlotDetails } from '../OfferSlotDetails';

const ICON = { color: '#000', fontSize: '1.3em' };

export const ActiveOffers = ({ activeOffers, setRefresh }) => {
  const userId = useSelector((state) => state.auth.user);

  const activeofferColumns = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Item Name',
      accessor: 'itemName',
    },
    {
      Header: 'Amount',
      accessor: 'price',
    },
    {
      Header: 'Description',
      accessor: 'itemDescription',
    },
    {
      Header: 'Slots',
      accessor: 'initialSlots',
      Cell: ({ row }) => <Text>{`${row?.original?.usedSlots} of ${row?.original?.initialSlots}`}</Text>,
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => <Text>{dayjs(value).format('DD-MMM-YYYY')}</Text>,
    },
    {
      Header: 'Action',
      accessor: '_id',
      Cell: ({ row }) => (
        <>
          {' '}
          <HStack spacing='5'>
            <Tooltip label='view participants'>
              <Box>
                <Modal icon={<BsThreeDots {...ICON} />} title='Slot participants'>
                  <OfferSlotDetails setRefresh={setRefresh} offerDetails={row.original} />
                </Modal>
              </Box>
            </Tooltip>

            <Tooltip label='edit offer'>
              <Box>
                <Modal icon={<FiEdit {...ICON} color='green' />} title='Edit Offer'>
                  <EditAnOfferForm rowId={row?.original} setRefresh={setRefresh} />
                </Modal>
              </Box>
            </Tooltip>

            <Box color='crimson'>
              <CautionAlertDialog
                onContinue={() => handleDelete(row.original)}
                cautionTitle='Are you sure you want to cancel this offer?'
                icon={<BsTrash />}
              />
            </Box>
          </HStack>
        </>
      ),
    },
  ];

  const handleDelete = (offer) => {
    const payload = {
      ...offer,
      userId,
      status: 'Canceled',
    };
    cancelOffer(userId, payload).then(setRefresh);
  };

  return (
    <>
      <CustomTable COLUMNS={activeofferColumns} DATA={activeOffers} />
    </>
  );
};
