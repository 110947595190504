import { Td } from "@chakra-ui/react"

export const TableHeadMobile = () => {
    return (
        <>
             <Td> S/N</Td>
             <Td> Errand Type</Td>
             <Td> Errand unit</Td>
             <Td> Total Errands</Td>
        </>
    )
}