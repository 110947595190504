import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";
import { ActiveTable } from "../SecurityActiveTable";
import { useSelector } from "react-redux";
import { BannedTable } from "../SecurityBannedTable";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import {
  getActiveSecurities,
  getBannedSecurities,
} from "../../service/getSecurities";
import FullPageLoader from "../../../../components/full-page-loader";

export const SecurityPanel = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [activeSecurities, setActiveSecurities] = useState([]);
  const [bannedSecurities, setBannedSecurities] = useState([]);
  const [refresh, setRefresh] = useState({});

  const NO_PADDING = { padding: "none" };
  const PD = { padding: ["10px", "10px", "20px"] };

  useEffect(() => {
    getActiveSecurities(setLoading, userId, setActiveSecurities);
    getBannedSecurities(setLoading, userId, setBannedSecurities);
  }, [refresh, userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Tabs color="grey" colorScheme="yellow">
      <TabList>
        <Tab
          key={nanoid()}
          outline="none !important"
          _focus={{ boxShadow: "none" }}
          w="180px"
          _selected={{
            color: "#26D637",
            borderBottom: "4px solid #26D637",
            borderBottomRadius: "3px",
          }}
        >
          Active Securities
        </Tab>
        <Tab
          key={nanoid()}
          outline="none !important"
          _focus={{ boxShadow: "none" }}
          w="180px"
          _selected={{
            color: "#D6B738",
            borderBottom: "4px solid #D6B738",
            borderBottomRadius: "3px",
          }}
        >
          Banned Securities
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel {...NO_PADDING}>
          <Box bg="#fff" {...PD}>
            <ActiveTable
              refresh={() => setRefresh({})}
              updateTable={(page) =>
                getActiveSecurities(
                  setLoading,
                  userId,
                  setActiveSecurities,
                  page
                )
              }
              getData={activeSecurities}
              title="Create Security"
            />
          </Box>
        </TabPanel>
        <TabPanel {...NO_PADDING}>
          <Box bg="#fff" {...PD}>
            <BannedTable
              refresh={() => setRefresh({})}
              updateTable={(page) =>
                getBannedSecurities(
                  setLoading,
                  userId,
                  setBannedSecurities,
                  page
                )
              }
              getData={bannedSecurities}
              title="Create Security"
            />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
