import { Box, Flex, Text } from "@chakra-ui/layout";
import house from "../../assets/house.png";
import { LoginForm } from "./components/LoginForm";

export const Login = () => {
  const IMG_STYLE = {
    width: "auto",
    height: "100vh",
    minWidth: "100%",
    backgroundSize: "cover",
    gridArea: "1/1/2/2",
  };
  const BOX1 = {
    flex: "1.6",
    justifyItems: "center",
    background: "#fff",
    overflow: "hidden",
  };
  const OVERLAY = {
    width: "100%",
    height: "100%",
    background: "#150d777d",
    gridArea: "1/1/2/2",
  };
  const BOX2 = {
    display: "flex",
    color: "#fff",
    textAlign: "left",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    gridArea: "1/1/2/2",
  };
  const BOX3 = { display: "flex", flexDirection: "column", alignItems: "end" };
  const BOX4 = { bg: "#FF897A", m: "2px 0", h: "4px" };
  const TEXT_STYLE = {
    color: "#614285",
    fontSize: "1.5em",
    fontWeight: "bold",
    marginBottom: "30px",
  };

  return (
    <Flex bg="#fff" alignItems="center" w="100%">
      <Box {...BOX1} display={["none", "none", "grid"]}>
        <img src={house} alt="home" style={IMG_STYLE} />

        {/* overlay */}
        <Box {...OVERLAY} />

        <Box {...BOX2}>
          <Box>
            <Text fontSize=".9em">Welcome to</Text>
            <Text fontSize="2.5em">
              <b>My Estate</b>
            </Text>
            <Box {...BOX3}>
              <Box w="100px" {...BOX4} />
              <Box w="60px" {...BOX4} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box flex="1" p={["20px", "30px", "70px"]}>
        <Text {...TEXT_STYLE}>Admin Login</Text>
        <LoginForm />
      </Box>
    </Flex>
  );
};
