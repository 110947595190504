import React from 'react';
import './App.css';
import AuthenticatedUserApp from './app/authenticatedApp';
import FullPageLoader from './components/full-page-loader';
import { useDispatch, useSelector } from 'react-redux';
import { authSetUser } from './store/actions/authAction';
import jwtDecode from 'jwt-decode';
import UnAuthenticatedApp from './app/unAuthenticatedApp';

import { baseURL } from './services/api';
import { urlBase64ToUint8Array } from './send-sw-details';

const { useState, useEffect } = React;

const WEB_PUSH_PUBLIC_KEY = 'BL_9glFng3bdStxfOmhe7M3jT06FzBTUZsjbF8dFvBEhBslgVxay_mYJtmp8t3GevVCBzXyz-RxbkaYLSa3ZYOk';

function App() {
  const userId = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);
  // check if user is authenticated and get his details and store to global state
  useEffect(() => {
    // Handle user state changes
    const getAuthData = async (id) => {
      dispatch(authSetUser(id));
      setIsLoggedIn(true);
      if (checkingStatus) setCheckingStatus(false);
    };

    const deleteTokenAndKickUserOut = async () => {
      localStorage.removeItem('11#221#');
      if (checkingStatus) setCheckingStatus(false);
    };

    const token = localStorage.getItem('11#221#');

    if (token) {
      const decoded = jwtDecode(token);
      const expiryDate = new Date(decoded.exp * 1000);
      return new Date() > expiryDate ? deleteTokenAndKickUserOut() : getAuthData(decoded.userId);
    }
    return deleteTokenAndKickUserOut();
  }, [checkingStatus, dispatch]);
  // End check !!

  useEffect(() => {
    if (!userId) return;

    async function subscribe() {
      const sw = await navigator.serviceWorker.ready;
      const push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_PUBLIC_KEY),
      });

      const payload = { subscription: push, userId };

      await fetch(`${baseURL}/push-notification/notifications/subscription`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/json',
        },
      });
    }
    subscribe();

    if ('serviceWorker' in navigator) {
      window.addEventListener('load', async () => {
        await navigator.serviceWorker.register('/sw.js');
      });
    }
  }, [userId]);

  if (checkingStatus) {
    return <FullPageLoader />;
  }

  if (isLoggedIn) {
    return <AuthenticatedUserApp />;
  }

  return <UnAuthenticatedApp />;
}

export default App;
