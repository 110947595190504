import React from "react";
import { Modal } from "../../modal";
import AddErrandTypesForm from "../../../components/addErrandTypesForm";

import { PaginatedTable } from "../../../components/PaginatedTable";
import { ErrandTypesHead } from "./table-data/TableHead";
import { ErrandTypesBody } from "./table-data/TableBody";
import { Box, Flex, Text } from "@chakra-ui/layout";

export const ErrandTypes = ({ errandTypes, updateTable }) => {
  return (
    <>
      <Box bg="#fff" px={10} pb={10}>
        <Flex
          py={3}
          justifyContent="space-between"
          alignItems="center"
          borderBottom="2px solid #f4f4f4"
        >
          <Text color="#A08CA8" fontWeight="600">
            Errand types
          </Text>
          <Modal title="Add Errand Type" buttonText="Add Errand Type">
            <AddErrandTypesForm updateTable={() => updateTable()} />
          </Modal>
        </Flex>
        <PaginatedTable
          columns={ErrandTypesHead}
          data={
            <ErrandTypesBody
              errandTypes={errandTypes}
              updateTable={() => updateTable()}
            />
          }
          length={errandTypes?.length}
          updateTable={(page) => updateTable(page)}
        />
      </Box>
    </>
  );
};
