import { errorNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const loginUser = async (payload, setLoading) => {
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.ADMIN_LOGIN, payload);
    const token = result?.data?.token;
    localStorage.setItem('11#221#', token);
    window.location.href = '/home';
  } catch (e) {
    setLoading(false);
    errorNotifier(e?.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};
