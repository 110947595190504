import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const deleteErrandBoy = async (payload, id) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_ERRAND_BOY(payload.userId, id), payload);
    successNotifier('Errand boy deleted');
  } catch (e) {
    errorNotifier(e.response.data.result?.message);
  }
};
