// import {
//   errorNotifier,
// } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getNotifications = async (setNotification) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_NOTIFICATIONS);
    setNotification(data.notifications);
  } catch (e) {
    // errorNotifier(e.response?.data?.result?.message);
  }
};
