import { Td } from "@chakra-ui/react";

export const TableHead = () => {
  return (
    <>
      <Td> S/N</Td>
      <Td> Errand Type</Td>
      <Td> Errand unit</Td>
      <Td> Total Errands</Td>
      <Td>Description</Td>
    </>
  );
};
