import { Td } from "@chakra-ui/react";

export const TableHead = () => {
  return (
    <>
      <Td> Product Name</Td>
      <Td> Category</Td>
      <Td> Total Clicks</Td>
    </>
  );
};
