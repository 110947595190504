import http, { AUTH_ROUTES } from '../../../services/api';
import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';

export const getCableSubscription = async (setLoading, userId, setCableSubscription) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_CABLE_SUBSCRIPTION(userId));

    setCableSubscription(data?.cablePlans);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result);
    setLoading(false);
  }
};

export const updateCableSubscription = async (payload, setLoading) => {
  try {
    const { data } = await http.put(AUTH_ROUTES.UPDATE_CABLE_SUBSCRIPTION(payload.userId, payload.dataSubId), payload);
    setLoading(false);

    successNotifier(data?.message);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const getEstateInfo = async (userId, setEstateInfo) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_INFO(userId));
    setEstateInfo(data);
  } catch (e) {
    // errorNotifier(e.response?.data?.result);
    // setEstateInfo(data);
  }
};

export const changeEstateInfo = async (payload, setEstateDetailsLoading) => {
  try {
    await http.post(AUTH_ROUTES.CHANGE_ESTATE_INFO, payload);
    successNotifier('Estate details saved successfully');
    setEstateDetailsLoading(false);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
    setEstateDetailsLoading(false);
  }
};

export const getEnergySubscription = async (setLoading, userId, setEnergySubscription) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ENERGY_SUBSCRIPTION(userId));

    setEnergySubscription(data);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const updateEnergySubscription = async (payload, setLoading) => {
  try {
    await http.post(AUTH_ROUTES.UPDATE_ENERGY_SUBSCRIPTION, payload);
    setLoading(false);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const getDataSubscription = async (setLoading, userId, setDataSubscription) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_DATA_SUBSCRIPTION(userId));

    setDataSubscription(data?.dataPlans);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const updateDataSubscription = async (payload, setLoading) => {
  setLoading(true);
  try {
    const { data } = await http.put(AUTH_ROUTES.UPDATE_DATA_SUBSCRIPTION(payload.userId, payload.dataSubId), payload);

    successNotifier(data.message);
    setLoading(false);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const syncDataPlans = async (userId, setLoading) => {
  setLoading(true);
  try {
    const { data } = await http.get(AUTH_ROUTES.SYNC_DATA_PLAN(userId));

    successNotifier(data?.message);
  } catch (e) {
    errorNotifier(e.response.data.message);
  } finally {
    setLoading(false);
  }
};
