import { Td, Tr } from "@chakra-ui/table"
import { nanoid } from "@reduxjs/toolkit"
import dayjs from "dayjs"

export const CreditTableBody = ({ getData }) => {
  return (
    <>
      {getData &&
        getData.map(data => {
          return (
            <Tr key={nanoid()}>
              <Td>{data?.userId?.tenantName}</Td>

              <Td>{data?.transactioninfo?.email}</Td>
              <Td>₦ {parseInt(data?.amount).toLocaleString()}</Td>
              <Td>₦ {parseInt(data?.balanceAfter).toLocaleString()}</Td>
              <Td>{data?.transactionChannel}</Td>
              <Td>{dayjs(data?.createdAt).format("HH:mm:a")}</Td>
              <Td>{dayjs(data?.createdAt).format("DD-MMM-YYYY")}</Td>
            </Tr>
          )
        })}
    </>
  )
}
