import React from "react";
import { PaginatedTable } from "../../../../components/PaginatedTable";
import { CreditTableBody } from "./tables/CreditTableBody";
import { CreditTableHead } from "./tables/CreditTableHead";

const CreditTable = ({ getTransactions, updateTable }) => {
  return (
    <PaginatedTable
      columns={CreditTableHead}
      data={
        <CreditTableBody
          getData={getTransactions}
          updateTable={() => updateTable()}
        />
      }
      length={getTransactions?.length}
      updateTable={(page) => updateTable(page)}
    />
  );
};

export default CreditTable;
