import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PendingTable } from "./PendingTable";
import { useSelector } from "react-redux";
import {
  getCanceledErrands,
  getCompletedErrands,
  getPendingErrands,
  getOngoingErrands,
} from "../../home/service/homeApi";
import { OngoingTable } from "./OngoingTable";
import { CanceledTable } from "./CanceledTable";
import { CompletedTable } from "./CompletedTable";
import { assignErrand } from "../service/assignErrand";
import FullPageLoader from "../../../components/full-page-loader";

export const StatusInnerTab = ({ get, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [pendingErrands, setPendingErrands] = useState([]);
  const [completedErrands, setCompletedErrands] = useState([]);
  const [canceledErrands, setCanceledErrands] = useState([]);
  const [ongoingErrands, setOngoingErrands] = useState([]);
  const [refresh, setRefresh] = useState({});
  const TAB_TITLES = ["Pending", "Ongoing", "Completed", "Canceled"];
  const PADDING = {
    paddingLeft: ["13px", "13px", "20px !important"],
    paddingRight: ["13px", "13px", "20px !important"],
  };

  useEffect(() => {
    getPendingErrands(setLoading, userId, setPendingErrands);
    getCompletedErrands(setLoading, userId, setCompletedErrands);
    getCanceledErrands(setLoading, userId, setCanceledErrands);
    getOngoingErrands(setLoading, userId, setOngoingErrands);
  }, [refresh, userId]);

  const handleSubmit = (e, row) => {
    e.preventDefault();
    const payload = {
      ...row,
      status: "ONGOING",
      userId,
    };
    assignErrand(payload).then(() => updateTable());
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Tabs color="grey" colorScheme="yellow">
      <TabList>
        {TAB_TITLES.map((title, id) => {
          return (
            <Tab
              key={id}
              outline="none !important"
              _focus={{ boxShadow: "none" }}
              borderBottom="3px solid"
              {...PADDING}
            >
              <Text fontSize={["xs", "sm"]}>{title}</Text>
            </Tab>
          );
        })}
      </TabList>
      <TabPanels>
        <TabPanel>
          <PendingTable
            updateTable={(page) =>
              getPendingErrands(setLoading, userId, setPendingErrands, page)
            }
            refresh={() => setRefresh({})}
            errand={pendingErrands}
          />
        </TabPanel>
        <TabPanel>
          <OngoingTable
            handleReAssign={handleSubmit}
            updateTable={(page) =>
              getOngoingErrands(setLoading, userId, setOngoingErrands, page)
            }
            refresh={() => setRefresh({})}
            errand={ongoingErrands}
          />
        </TabPanel>
        <TabPanel>
          <CompletedTable
            errand={completedErrands}
            updateTable={(page) =>
              getCompletedErrands(setLoading, userId, setCompletedErrands, page)
            }
            refresh={() => setRefresh({})}
          />
        </TabPanel>
        <TabPanel>
          <CanceledTable
            errand={canceledErrands}
            updateTable={(page) =>
              getCanceledErrands(setLoading, userId, setCanceledErrands, page)
            }
            refresh={() => setRefresh({})}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
