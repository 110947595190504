import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getBanners = async (userId, setLoading, setBanners) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_BANNER(userId));

    setBanners(data?.banners);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

export const deleteBanner = async (userId, bannerId, setRefresh, onClose) => {
  try {
    const {
      data: { result },
    } = await http.delete(AUTH_ROUTES.DELETE_BANNER(userId, bannerId));
    setRefresh({});
    onClose();

    successNotifier(result.message);
  } catch (err) {
    errorNotifier(err.response.data.result.message);
  }
};

export const createBanner = async (payload, setLoading, setRefresh) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.CREATE_BANNER, payload);

    successNotifier(result.message);
    setRefresh();
  } catch (e) {
    errorNotifier(e.response.data.result.message);
  } finally {
    setLoading(false);
  }
};
