import http, { AUTH_ROUTES } from '../../../services/api';
// import { errorNotifier } from "../../../components/NotificationHandler";
import { DATA_ROWS } from '../../../app/constants';

export const getActiveErrandBoys = async (setLoading, userId, setActiveErrandBoys, skip = 0, limit = DATA_ROWS.LIMIT, status = 'Active') => {
  const page = limit * skip;
  const params = {
    userId,
    skip: page,
    limit,
    status,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRAND_BOYS(params));

    setActiveErrandBoys(data.errandBoys);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data.result);
    setActiveErrandBoys([]);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const getBannedErrandBoys = async (setLoading, userId, setBannedErrandBoys, skip = 0, limit = DATA_ROWS.LIMIT, status = 'Banned') => {
  const page = limit * skip;
  const params = {
    userId,
    skip: page,
    limit,
    status,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRAND_BOYS(params));

    setBannedErrandBoys(data.errandBoys);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data.result);
    setBannedErrandBoys([]);
    setLoading(false);
  }
};

export const getErrandBoys = async (setLoading, userId, setErrandBoys, skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = limit * skip;
  const params = {
    userId,
    skip: page,
    limit,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRAND_BOYS(params));

    setErrandBoys(data.errandBoys);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data.result);
    setErrandBoys([]);
    setLoading(false);
  }
};
