import React from "react";
import { PaginatedTable } from "../../../components/PaginatedTable";
import { CategoryBody } from "./CategoryBody";
import { CategoryHead } from "./CategoryHead";

function ShopTable({ getCategories = [], updateTable }) {
  return (
    <PaginatedTable
      columns={CategoryHead}
      data={
        <CategoryBody
          getCategories={getCategories}
          updateTable={() => updateTable()}
        />
      }
      length={getCategories?.length}
      updateTable={(page) => updateTable(page)}
    />
  );
}

export default ShopTable;
