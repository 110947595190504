import { Box, Divider, Text } from "@chakra-ui/layout";
import { GlobalTable } from "../../withdrawal/components/global-table-template";
import { LightSubscriptionTableBody } from "./table-data/TableBody";
import { LightSubscriptionTableHead } from "./table-data/TableHead";

export const LightSubscription = ({ data }) => {
  return (
    <Box m="10px 0" p="20px" bg="#fff" boxShadow="md" borderRadius="5px">
      <Text color="#8366A5" marginBottom="10px">
        <b>Energy Subscription</b>
      </Text>
      <Divider />
      <Box h="300px" overflow="auto">
        <GlobalTable
          tableHead={<LightSubscriptionTableHead />}
          tableBody={<LightSubscriptionTableBody Data={data} />}
          striped={false}
        />
      </Box>
    </Box>
  );
};
