import { Button } from "@chakra-ui/button";
import { Box, Flex, HStack, Spacer, Text } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { AiOutlineDownload } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import CreditTable from "./credit/CreditTable";
import PaymentTable from "./payment/PaymentTable";
import TransferTable from "./transfer/TransferTable";
import { creatCsvFile, downloadFile } from "download-csv";
import dayjs from "dayjs";
import { Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getTransactions,
  getCreditTransactions,
  getTransferTransactions,
  getDebitTransactions,
} from "../service/financeApi";
import FullPageLoader from "../../../components/full-page-loader";

export const FinanceTable = () => {
  const [transactions, setTransactions] = useState([]);
  const userId = useSelector((state) => state.auth.user);
  const [creditTransactions, setCreditTransactions] = useState([]);
  const [transferTransactions, setTransferTransactions] = useState([]);
  const [debitTransactions, setDebitTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTransactions(setLoading, userId, setTransactions);

    getCreditTransactions(setLoading, userId, setCreditTransactions);
    getTransferTransactions(setLoading, userId, setTransferTransactions);
    getDebitTransactions(setLoading, userId, setDebitTransactions);
  }, [userId]);
  // console.log("trans", transactions)

  const TABS = ["Credit", "Transfer", "Debit"];
  const columns = {
    username: "username",
    email: "email",
    amount: "amount",
    balance: "balance",
    source: "source",
    transactionType: "transaction type",
    time: "time",
    date: "date",
  };

  const data = transactions.map((item) => ({
    username: item?.userId?.tenantName || "not Available",
    email: item?.transactioninfo?.email || "not Available",
    amount: parseInt(item?.amount),
    balance: parseInt(item?.balanceAfter),
    source: item?.transactionChannel,
    transactionType: item?.transactionType,
    time: dayjs(item?.createdAt).format("HH:mm:a"),
    date: dayjs(item?.userId?.createdAt).format("DD-MMM-YYYY"),
  }));

  const handleCsvDownload = () => {
    const csvFile = creatCsvFile(data, columns);
    downloadFile(csvFile);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <Flex mb={5} px={2} alignItems='flex-start' flexDirection={["column", "row"]}>
        <Text mb={["20px", "0"]} fontSize={["1.5em", '2em']} fontWeight="bold" color="#A08CA8">
          Funding History
        </Text>
        <Spacer />
        <Flex alignItems="center">
          <Box
            marginRight="20px"
            border="1px solid #B2B2B2"
            p={1}
            w="80px"
            cursor="pointer"
            onClick={handleCsvDownload}
          >
            <Tooltip label="download history">
              <HStack spacing={3}>
                <Text color="#656363" fontWeight="semi-bold">
                  CSV
                </Text>
                <AiOutlineDownload />
              </HStack>
            </Tooltip>
          </Box>

          <NavLink to="/finances/fund-wallet">
            <Button
            fontSize={["12px", "16px"]}
              bg="#FF897A"
              _hover={{ bg: "#FF897A" }}
              _focus={{ boShadow: "none" }}
              color="#ffffff"
              p={["7px 20px", "10px 40px"]}
            >
              Funded wallets
            </Button>
          </NavLink>
        </Flex>
      </Flex>
      <Tabs variant="line" borderColor=" #26D637">
        <TabList borderBottomWidth="1px">
          {TABS.map((tab, index) => (
            <Tab
              key={index}
              _selected={{
                borderBottom: "4px solid #26D637",
                borderBottomRadius: "3px",
              }}
              _focus={{ boxShadow: "none" }}
              w="160px"
              color="#656363"
            >
              {tab}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel p={["0", "0", "auto"]}>
            <CreditTable
              getTransactions={creditTransactions}
              updateTable={(page) =>
                getCreditTransactions(
                  setLoading,
                  userId,
                  setCreditTransactions,
                  page
                )
              }
            />
          </TabPanel>
          <TabPanel p={["0", "0", "auto"]}>
            <TransferTable
              getTransactions={transferTransactions}
              updateTable={(page) =>
                getTransferTransactions(
                  setLoading,
                  userId,
                  setTransferTransactions,
                  page
                )
              }
            />
          </TabPanel>
          <TabPanel p={["0", "0", "auto"]}>
            <PaymentTable
              getTransactions={debitTransactions}
              updateTable={(page) =>
                getDebitTransactions(
                  setLoading,
                  userId,
                  setDebitTransactions,
                  page
                )
              }
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
