import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FullPageLoader from "../../components/full-page-loader";
import { TopCards } from "./components/TopCards";
import { SubscriptionTable } from "./components/SubscriptionTable";
import { getSubscriptions, getDashboard } from "./service/subscriptionApi";
import { DATA_ROWS } from "../../app/constants";

export const Subscription = () => {
  const userId = useSelector((state) => state.auth.user);
  const [subscription, setSubscription] = useState([]);
  const [dashData, setDashData] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cancelFilter, setCanceFilter] = useState(false);

  useEffect(() => {
    getDashboard(setLoading, userId, setDashData);
    getSubscriptions(setLoading, userId, setSubscription);  
  }, [userId, loading]);

  useEffect(() => {
    setFilteredList(subscription);
  }, [subscription]);

  useEffect(() => {
    setCanceFilter(false);
  }, [filteredList, subscription]);

  //FILTER BY DATE AND MONTH
  const filteredSubscription = (inputMonth, inputDate) => {
    const filteredSubscription = subscription.filter((data) => {
      const startMonth = new Intl.DateTimeFormat("en-US", { month: "long" })
        .format(new Date(data.startDate))
        .toLowerCase();
      const endMonth = new Intl.DateTimeFormat("en-US", { month: "long" })
        .format(new Date(data.endDate))
        .toLowerCase();
      const date = new Date(data.endDate).getDate();
      const filterByMonth = (startMonth + " " + date).includes(
        inputMonth.toLowerCase() + " " + inputDate.toLowerCase()
      );
      const filterByMonthEnd = (endMonth + " " + date).includes(
        inputMonth.toLowerCase() + " " + inputDate.toLowerCase()
      );
      return filterByMonth || filterByMonthEnd;
    });
    setFilteredList(filteredSubscription);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <TopCards
        content={dashData.slice(0, 2)}
        getSubscription={subscription}
        filteredSubscription={filteredSubscription}
        cancelFilter={(bool) => setCanceFilter(bool)}
      />
      <SubscriptionTable
        getSubscription={cancelFilter ? subscription : filteredList}
        updateTable={(page) =>
          getSubscriptions(
            setLoading,
            userId,
            setSubscription,
            page,
            DATA_ROWS.LIMIT
          )
        }
      />
    </>
  );
};
