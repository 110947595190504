import React from "react";
import { Flex, Box, Text, Heading, HStack, Spacer } from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import { Modal } from "../../../modal";
import AddFundToWalletForm from "../../../../components/addFundToWalletForm";
import { useHistory } from "react-router";
import ArrowLeft from "./assets/svgs/ArrowLeft";
import dayjs from "dayjs";
import { AiOutlineDownload } from "react-icons/ai";
import { creatCsvFile, downloadFile } from "download-csv";
import { FundedWalletListHead } from "./FundedWalletListHead";
import { FundedWalletListBody } from "./FundedWalletListBody";
import { PaginatedTable } from "../../../../components/PaginatedTable";

export default function FundWallet({
  setTenantPhone,
  updateTable,
  getData,
  refresh
}) {
  let history = useHistory();

  const csvColumns = {
    name: "name",
    amount: "amount",
    description: "description",
    date: "date",
  };

  const csvData = getData.map((item) => ({
    name: item?.userId?.tenantName,
    amount: parseInt(item?.amount),
    description: item?.description,
    date: dayjs(item?.createdAt).format("DD-MMM-YYYY"),
  }));

  const handleCsvDownload = () => {
    const csvFile = creatCsvFile(csvData, csvColumns);
    downloadFile(csvFile);
  };

  return (
    <>
      <Box bg="#ffffff" mt="50px" p="20px">
        <Flex mb={5} px={2} flexDirection={["column", "row"]}>
          <HStack justifyContent="space-between">
            <Button
              bg="none"
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowLeft fontSize="2em" />
            </Button>
            <Heading
              fontSize={["14px", "20px"]}
              color="#A08CA8"
              fontWeight="semibold"
            >
              Funded wallets
            </Heading>
          </HStack>
          <Spacer />
          <HStack alignItems="center" justifyContent="flex-end">
            <Box
              marginRight="20px"
              border="1px solid #B2B2B2"
              p={1}
              w="80px"
              cursor="pointer"
              onClick={handleCsvDownload}
            >
              <HStack spacing={3}>
                <Text color="#656363" fontWeight="semi-bold">
                  CSV
                </Text>
                <AiOutlineDownload />
              </HStack>
            </Box>

            <Modal title="Fund Wallet" buttonText="Add Fund">
              <AddFundToWalletForm
                setTenantPhone={setTenantPhone}
                updateTable={() => refresh()}
              />
            </Modal>
          </HStack>
        </Flex>
        <PaginatedTable
          columns={FundedWalletListHead}
          data={<FundedWalletListBody getData={getData} />}
          length={getData?.length}
          updateTable={(page) => updateTable(page)}
        />
      </Box>
    </>
  );
}
