import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, HStack, Spacer, Text, VStack, Circle } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import storeIcon from '../../assets/store.svg';
import { AccordionTable } from './Components/accordion-table';
import { TopCards } from '../../components/TopCards';
import { useSelector } from 'react-redux';
import AccordionHOC from '../../components/accordionHOC';
import FullPageLoader from '../../components/full-page-loader';
import { getTopProducts, getDashboard, getPendingShop, getApprovedShop, editShop, deleteShop } from './service/shopApi';
import { Modal } from '../modal';
import { CreateBanner } from './Components/banner/CreateBanner';
import BannerSettings from './Components/banner';
import { getBanners } from './service';
import { CustomTable } from '../../components/customized table/CustomTable';
import dayjs from 'dayjs';
import { Tooltip } from '@chakra-ui/react';
import { BiMessageAltDetail } from 'react-icons/bi';
import CautionAlertDialog from '../../components/CautionAlertDialog';
import { FaTimes } from 'react-icons/fa';
import { MessageForm } from './Components/MessageForm';

const ICON = { color: '#fc8181', fontSize: '1.3em' };

export const Shop = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [pendingProductData, sePendingProductData] = useState([]);
  const [approvedProductData, seApprovedProductData] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [dashData, setDashData] = useState([]);
  const [refresh, setRefresh] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    getPendingShop(setLoading, userId, sePendingProductData);
    getApprovedShop(setLoading, userId, seApprovedProductData);
    getTopProducts(setLoading, userId, setTopProducts);
    getDashboard(setLoading, userId, setDashData);
    getBanners(userId, setLoading, setBanners);
  }, [userId, refresh]);

  const pendingShop = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Resident Name',
      accessor: 'userId.tenantName',
    },
    {
      Header: 'Category',
      accessor: 'category',
    },
    {
      Header: 'Title',
      accessor: 'itemName',
    },
    {
      Header: 'Description',
      accessor: 'itemDescription',
    },
    {
      Header: 'Amount',
      accessor: 'price',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Phone number',
      accessor: 'phoneNumber',
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => <Text>{dayjs(value).format('DD-MMM-YYYY')}</Text>,
    },
    {
      Header: 'Action',
      accessor: '_id',
      Cell: ({ row }) => (
        <HStack>
          <Button
            _hover={{ background: '#50a650', color: '#fff' }}
            color='#757575'
            fontSize='.7em'
            size='xs'
            py='15px'
            px='8px'
            bg='#D4D4D4'
            _focus={{ borderRadius: 'none' }}
            onClick={() => handleApproved(row.original)}
          >
            Approve
          </Button>
          <VStack spacing={2}>
            <Tooltip label='send message'>
              <Box>
                <Modal icon={<BiMessageAltDetail {...ICON} color='green' />}>
                  <MessageForm tel={row.original.userId?.tenantPhone} />
                </Modal>
              </Box>
            </Tooltip>

            <Tooltip label='cancel'>
              <Box>
                <CautionAlertDialog
                  onContinue={() => handleRemove(row.original._id)}
                  cautionTitle='Are you sure you want to remove this product?'
                  icon={<FaTimes {...ICON} />}
                />
              </Box>
            </Tooltip>
          </VStack>
        </HStack>
      ),
    },
  ];

  const handleRemove = (rowId) => {
    deleteShop(userId, rowId).then(() => setRefresh({}));
  };

  const handleApproved = (data) => {
    // const payload = { status: 'APPROVED', userId, ...data };

    editShop(userId, data._id).then(() => setRefresh({}));
  };

  const handleDelete = (rowId) => {
    deleteShop(userId, rowId).then(() => setRefresh({}));
  };

  const approved = [
    {
      Header: 'S/N',
      accessor: 'id',
      Cell: ({ row }) => Number(row.id) + 1,
    },
    {
      Header: 'Resident Name',
      accessor: 'userId.tenantName',
    },
    {
      Header: 'Category',
      accessor: 'category',
    },
    {
      Header: 'Title',
      accessor: 'itemName',
    },
    {
      Header: 'Description',
      accessor: 'itemDescription',
    },
    {
      Header: 'Amount',
      accessor: 'price',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Phone number',
      accessor: 'phoneNumber',
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => <Text>{dayjs(value).format('DD-MMM-YYYY')}</Text>,
    },
    {
      Header: 'Action',
      accessor: '_id',
      Cell: ({ row }) => (
        <Tooltip label='remove'>
          <Circle bg='#d9d9d9' w='20px' h='20px' pr={1}>
            <CautionAlertDialog
              onContinue={() => handleDelete(row.original._id)}
              cautionTitle='Are you sure you want to remove?'
              icon={<FaTimes size='12px' {...ICON} />}
            />
          </Circle>
        </Tooltip>
      ),
    },
  ];

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <TopCards content={dashData} />
      <AccordionHOC title='Top Products'>
        <AccordionTable getTable={topProducts.adverts} />
      </AccordionHOC>

      <Box bg='#fff' p={5}>
        <HStack mb={6} px={2} pt={5} pb={3} borderBottom='1px' borderColor='gray.200'>
          <HStack>
            <Box mr='5px'>
              <img width='25px' src={storeIcon} alt='shop' />
            </Box>
            <Box w='1px' h='20px' bg='gray.100'></Box>
            <Text pl='8px' fontSize='14px' color='#A08CA8' fontWeight='semibold'>
              All Product
            </Text>
          </HStack>
          <Spacer />
          {currentTab === 2 ? (
            <Modal small title={<Text>Upload banner</Text>} buttonText='Create banner'>
              <CreateBanner setRefresh={() => setRefresh({})} />
            </Modal>
          ) : (
            <NavLink
              to={{
                pathname: '/shop/category',
              }}
            >
              <Button size='sm' bg='#FF6F6F' color='#fff' _hover={{ bg: '#FF6F6F' }} px='15px' borderRadius='3px' fontSize='12px'>
                View Categories
              </Button>
            </NavLink>
          )}
        </HStack>
        <Tabs variant='unstyled' borderColor=' #26D637' onChange={(t) => setCurrentTab(t)}>
          <TabList mb={4}>
            <Tab
              _selected={{
                borderBottom: '4px solid #A08CA8',
                borderBottomRadius: '3px',
              }}
              _focus={{ boxShadow: 'none' }}
              w='170px'
              color='#656363'
            >
              Pending
            </Tab>
            <Tab
              _selected={{
                borderBottom: '4px solid #26D637',
                borderBottomRadius: '3px',
              }}
              _focus={{ boxShadow: 'none' }}
              w='170px'
              color='#656363'
            >
              Approved
            </Tab>
            <Tab
              _selected={{
                borderBottom: '4px solid #614285',
                borderBottomRadius: '3px',
              }}
              _focus={{ boxShadow: 'none' }}
              w='170px'
              color='#656363'
            >
              Banner Settings
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={['0', '0', 'auto']}>
              <CustomTable COLUMNS={pendingShop} DATA={pendingProductData} />
            </TabPanel>
            <TabPanel p={['0', '0', 'auto']}>
              <CustomTable COLUMNS={approved} DATA={approvedProductData} />

              {/* <Approved
                refresh={() => setRefresh({})}
                updateTable={(page) =>
                  getApprovedShop(
                    setLoading,
                    userId,
                    seApprovedProductData,
                    page
                  )
                }
                productData={approvedProductData}
              /> */}
            </TabPanel>
            <TabPanel p={['0', '0', 'auto']}>
              <BannerSettings banners={banners} setRefresh={() => setRefresh({})} setCurrentTab={setCurrentTab} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};
