import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  HStack,
  Img,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { createCategory } from '../service/shopApi';
import { AiFillPlusCircle } from 'react-icons/ai';
import {
  // errorNotifier,
  warningNotifier,
} from '../../../components/NotificationHandler';

const CreateCategoryForm = ({ onClose, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const initialValues = { categoryName: '', description: '' };
  const [formValues, setFormValues] = useState(initialValues);
  const [categoryIcon, setCategoryIcon] = useState({});

  const handleSubmit = () => {
    setLoading(true);
    const { categoryName, description } = formValues;

    if (!categoryIcon.file) {
      warningNotifier('Please add image for the category');
      setLoading(false);
      return;
    }
    if (!categoryName) {
      warningNotifier('Please enter the category name');
      setLoading(false);
      return;
    }
    if (!description) {
      warningNotifier('Please the category description');
      setLoading(false);
      return;
    }

    const formData = new FormData();

    formData.append('userId', userId);
    formData.append('categoryName', categoryName);
    formData.append('image', categoryIcon.file);
    formData.append('description', description);

    createCategory(formData, setLoading, onClose).then(() => updateTable());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFileInput = (e) => {
    e.preventDefault();
    if (!e.target.files) return;

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setCategoryIcon({ imageUrl: reader.result, file: e.target.files[0] });
    };
  };

  return (
    <FormControl id='createCategories' display='flex' flexDirection='column'>
      <FormLabel>Name</FormLabel>
      <Input
        name='categoryName'
        value={formValues.categoryName}
        onChange={handleChange}
        mb={4}
        variant='filled'
        type='text'
      />
      <HStack py={5}>
        <FormLabel htmlFor='input-file' mb={0} mr={0}>
          <AiFillPlusCircle
            color='#FF897A'
            style={{ cursor: 'pointer' }}
            fontSize='1.5rem'
          />
          {!categoryIcon.file && 'click to upload add image'}
        </FormLabel>

        <Input
          style={{ display: 'none' }}
          type='file'
          name='offerImage'
          id='input-file'
          accept='image/*'
          onChange={handleFileInput}
        />
        {categoryIcon.imageUrl && (
          <Img h='60px' src={categoryIcon.imageUrl} alt='category icon' />
        )}
      </HStack>
      <FormLabel>Description</FormLabel>
      <Textarea
        name='description'
        value={formValues.description}
        onChange={handleChange}
        mb={8}
        variant='filled'
        h={32}
      />
      <Button
        isLoading={loading}
        bg='#8366A5'
        _hover={{ bg: '#8366A5' }}
        _focus={{ boxShadow: 'none' }}
        color='#fff'
        h={50}
        fontWeight='medium'
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </FormControl>
  );
};

export default CreateCategoryForm;
