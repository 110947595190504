import { Box, Flex, Text } from '@chakra-ui/layout';
import { AccordionTable } from './components/accordion-table';
import { TopCards } from '../../components/TopCards';
import AccordionHOC from '../../components/accordionHOC';
import { Modal } from '../modal';
import CreateAnOfferForm from '../../components/createAnOfferForm';
import {
  getActiveOffers,
  getCanceledOffers,
  getCardData,
  getCompletedOffers,
  getTopOffers,
} from './service/offer';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FullPageLoader from '../../components/full-page-loader';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { ActiveOffers } from './components/paginated tables/ActiveOffers';
import { Completedoffers } from './components/paginated tables/Completedoffers';
import { CanceledOffers } from './components/paginated tables/CanceledOffers';

export const Offers = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [activeOffers, setActiveOffers] = useState([]);
  const [completedOffers, setCompletedOffers] = useState([]);
  const [canceledOffers, setCanceledOffers] = useState([]);
  const [topOffer, setTopOffer] = useState({});
  const [refresh, setRefresh] = useState({});
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    getActiveOffers(setLoading, userId, setActiveOffers);
    getCompletedOffers(setLoading, userId, setCompletedOffers);
    getCanceledOffers(setLoading, userId, setCanceledOffers);
    getTopOffers(setLoading, userId, setTopOffer);

    getCardData(userId, setCardData);
  }, [userId, refresh]);

  const card_data = [
    {
      title: 'Active Offers',
      number: cardData?.totalActiveOffers || 0,
      color: '#A08CA8',
    },
    {
      title: 'Total Products',
      number: cardData?.totalProducts || 0,
      color: '#FF897A',
    },
    { title: 'Total Offers', number: cardData?.totalOffers, color: '#FFBDB5' },
    {
      title: 'Total Clicks',
      number: cardData?.totalOfferView || 0,
      color: '#FF897A',
    },
  ];

  const TABS = ['Active', 'Completed', 'Canceled'];

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <TopCards content={card_data} display='none' />

      <AccordionHOC title='Top Offers'>
        <AccordionTable topOffers={topOffer?.offers} />
      </AccordionHOC>

      <Box bg='#fff' padding='20px'>
        <Flex justifyContent='space-between'>
          <Text fontWeight='bold' color='#614285'>
            All Offers
          </Text>
          <Modal title='Create Offer' buttonText='Create Offer'>
            <CreateAnOfferForm updateTable={(page) => setRefresh({})} />
          </Modal>
        </Flex>
        <Tabs>
          <TabList>
            {TABS.map((item) => {
              return (
                <Tab key={nanoid()} _focus={{ boxShadow: 'none' }}>
                  {item}
                </Tab>
              );
            })}
          </TabList>

          <TabPanels>
            <TabPanel>
              <ActiveOffers
                activeOffers={activeOffers}
                setRefresh={() => setRefresh({})}
              />
              {/* <ActiveOffersTable
                offersData={activeOffers.offers}
                refresh={() => setRefresh({})}
                updateTable={(page) =>
                  getActiveOffers(setLoading, userId, setActiveOffers, page)
                }
              /> */}
            </TabPanel>
            <TabPanel>
              <Completedoffers
                completedOffers={completedOffers}
                setRefresh={() => setRefresh({})}
              />
              {/* <CompletedOffersTable
                refresh={() => setRefresh({})}
                offersData={completedOffers.offers}
                updateTable={(page) =>
                  getCompletedOffers(
                    setLoading,
                    userId,
                    setCompletedOffers,
                    page
                  )
                }
              /> */}
            </TabPanel>
            <TabPanel>
              <CanceledOffers
                canceledOffers={canceledOffers}
                setRefresh={() => setRefresh({})}
              />
              {/* <CanceledOffersTable
                refresh={() => setRefresh({})}
                offersData={canceledOffers.offers}
                updateTable={(page) =>
                  getCanceledOffers(setLoading, userId, setCanceledOffers, page)
                }
              /> */}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};
