import http, { AUTH_ROUTES } from '../../../services/api';
import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import { DATA_ROWS } from '../../../app/constants';

const cardData = [
  {
    title: 'Total Products',
    number: 0,
    key: 'totalProducts',
    color: '#A08CA8',
  },
  {
    title: 'Total Clicks',
    number: 0,
    key: 'totalOfferView',
    color: '#FF897A',
  },
  {
    title: 'Total Sellers',
    number: 0,
    key: 'totalSellers',
    color: '#FFBDB5',
  },
];

export const getShop = async (
  setLoading,
  userId,
  setProducts
  // skip = 0,
  // limit = DATA_ROWS.LIMIT
) => {
  const params = {
    userId,
    // skip,
    // limit,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SHOP(params));

    setProducts(data);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result?.message);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const getPendingShop = async (setLoading, userId, setPendingProducts, skip = 0, limit = DATA_ROWS.LIMIT, status = 'PENDING') => {
  const page = skip * limit;
  const params = {
    userId,
    skip: page,
    limit,
    status,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SHOP(params));
    setPendingProducts(data.adverts);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result?.message);
    setPendingProducts([]);
    setLoading(false);
  }
};

export const getApprovedShop = async (setLoading, userId, setApprovedProducts, skip = 0, limit = DATA_ROWS.LIMIT, status = 'APPROVED') => {
  const page = skip * limit;
  const params = {
    userId,
    skip: page,
    limit,
    status,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SHOP(params));

    setApprovedProducts(data.adverts);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result?.message);
    setApprovedProducts([]);
    setLoading(false);
  }
};

export const getTopProducts = async (setLoading, userId, setTopProducts) => {
  const params = {
    userId,
    sort: 'desc',
    limit: '5',
    skip: 0,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SHOP(params));

    setTopProducts(data);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result?.message);
    setLoading(false);
  }
};

export const editShop = async (userId, advertId) => {
  try {
    const {
      data: { result },
    } = await http.put(AUTH_ROUTES.EDIT_SHOP(userId, advertId), {
      status: 'APPROVED',
    });

    successNotifier(result.message);
  } catch (e) {
    errorNotifier(e.response?.data?.result?.message);
  }
};

export const deleteShop = async (userId, rowId) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_SHOP(userId, rowId));
  } catch (e) {
    errorNotifier(e.response?.data?.result?.message);
  }
};

export const getCategories = async (setLoading, userId, setCategoriesData, skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = limit * skip;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SHOP_CATEGORY(userId, page, limit));
    setCategoriesData(data?.categories);

    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result?.message);
    setCategoriesData([]);
    setLoading(false);
  }
};

export const updateCategory = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_CATEGORY(payload.userId, payload._id), payload);
    successNotifier('Updated susscessfully');
  } catch (e) {
    errorNotifier(e.response?.data?.result?.message);
  }
};

export const createCategory = async (payload, setLoading, onClose) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_SHOP_CATEGORY, payload);
    setLoading(false);
    successNotifier('Created susscessfully');
    onClose();
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.result?.message);
  }
};

export const deleteShopCategory = async (userId, rowId) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_SHOP_CATEGORY(userId, rowId));
    successNotifier('Category deleted successfully');
  } catch (e) {
    errorNotifier(e.response?.data?.result?.message);
  }
};

//DASHBOARD ANALYTICS
export const getDashboard = async (setLoading, userId, setDashData) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_DASHBOARD(userId));

    const info = cardData.map((val) => {
      return { ...val, number: data[val.key] };
    });
    setDashData(info);
  } catch (e) {
    setDashData(cardData);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const sendMessage = async (payload, setLoading, userId) => {
  const params = { userId, ...payload };
  try {
    await http.post(AUTH_ROUTES.SEND_MESSAGE_TO_PARTICIPANTS, params);
    setLoading(false);
    successNotifier('Message sent susscessfully');
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};
