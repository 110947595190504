import React, { useEffect, useState } from "react";
import { TopCards } from "../../components/TopCards";
import "./components/Finance.css";
import { useSelector } from "react-redux";
import { getAnalytics, getFundWallet } from "./service/financeApi";
import FullPageLoader from "../../components/full-page-loader";
import { useLocation } from "react-router-dom";
import FundWallet from "./components/fundWallet/fundwallet";
import { FinanceTable } from "./components/FinanceTable";
import { DATA_ROWS } from "../../app/constants";

export const Finance = () => {
  const location = useLocation();
  const getPathname = location.pathname;
  const [analytics, setAnalytics] = useState([]);

  const card_data = [
    {
      title: "Total Money in wallet",
      number: analytics?.totalMoneyInWallet?.toLocaleString() || 0,
      color: "#A08CA8",
    },
    {
      title: "Total Credit",
      number: analytics?.totalCredit?.toLocaleString() || 0,
      color: "#64E270",
    },
    {
      title: "Total Transfer",
      number: analytics?.totalTransfer?.toLocaleString() || 0,
      color: "#FFBDB5",
    },
  ];

  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [fundWallet, setFundWallet] = useState([]);
  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    getAnalytics(setLoading, userId, setAnalytics);
    getFundWallet(setLoading, userId, setFundWallet);
    
  }, [userId, refresh]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <TopCards content={card_data} analytics={analytics} display="none" />
      {getPathname === "/finances" && <FinanceTable />}
      {getPathname === "/finances/fund-wallet" && (
        <FundWallet
          getData={fundWallet}
          refresh={()=> setRefresh({})}
          updateTable={(page) =>
            getFundWallet(
              setLoading,
              userId,
              setFundWallet,
              page,
              DATA_ROWS.LIMIT
            )
          }
        />
      )}
    </>
  );
};
