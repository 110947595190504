import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

const AccordionHOC = ({ title, children }) => {
  return (
    <Accordion mb={8} allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton
            bg="white"
            color="#A08CA8"
            border=".2px solid #fbb4ab"
            borderRadius="md"
            px={12}
            _focus={{ boxShadow: "none" }}
            _hover={{ background: "white" }}
          >
            <Box flex="1" textAlign="left" fontWeight="semibold">
              {title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionHOC;
