import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { addErrandBoy } from "../pages/errand/service/addErrandBoy";

const AddErrandBoysForm = ({ updateTable, onClose }) => {
  const getUserId = useSelector((state) => state.auth.user);
  const [userId] = useState(getUserId);
  const initialValues = {
    name: "",
    phoneNumber: "",
    estateAddress: "",
    description: "",
    userId,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    addErrandBoy(
      formValues,
      setLoading,
      setFormValues,
      initialValues,
      onClose
    ).then(() => updateTable());
  };
  return (
    <FormControl id="createCategories" display="flex" flexDirection="column">
      <FormLabel>Name</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        name="name"
        onChange={handleChange}
      />
      <FormLabel>Phone Nmuber</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        name="phoneNumber"
        onChange={handleChange}
      />
      <FormLabel>Estate Address</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        name="estateAddress"
        onChange={handleChange}
      />
      <FormLabel>Description</FormLabel>
      <Textarea
        mb={8}
        variant="filled"
        h={40}
        name="description"
        onChange={handleChange}
      />
      <Button
        bg="#8366A5"
        _hover={{ bg: "#8366A5" }}
        color="#fff"
        h={50}
        fontWeight="medium"
        onClick={handleSubmit}
        isLoading={loading}
      >
        Submit
      </Button>
    </FormControl>
  );
};

export default AddErrandBoysForm;
