import { DATA_ROWS } from '../../../app/constants';
import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getManagers = async (setLoading, userId, setManagers, skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: {
          data: { managers },
        },
      },
    } = await http.get(AUTH_ROUTES.GET_MANAGERS(userId, page, limit));
    setLoading(false);
    setManagers(managers);
  } catch (e) {
    setLoading(false);
    setManagers([]);
    // errorNotifier(e.response?.data.result);
  }
};

export const getActiveManagers = async (setLoading, userId, setActiveManagers, skip = 0, limit = DATA_ROWS.LIMIT, status = 'Active') => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: {
          data: { managers },
        },
      },
    } = await http.get(AUTH_ROUTES.GET_MANAGERS(userId, page, limit, status));
    setLoading(false);
    setActiveManagers(managers);
  } catch (e) {
    setLoading(false);
    setActiveManagers([]);
    // errorNotifier(e.response?.data.result);
  }
};
export const getBannedManagers = async (setLoading, userId, setBannedManagers, skip = 0, limit = DATA_ROWS.LIMIT, status = 'Banned') => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: {
          data: { managers },
        },
      },
    } = await http.get(AUTH_ROUTES.GET_MANAGERS(userId, page, limit, status));
    setLoading(false);
    setBannedManagers(managers);
  } catch (e) {
    setLoading(false);
    setBannedManagers([]);
    // errorNotifier(e.response?.data.result);
  }
};

export const updateManager = async (payload, setLoading, onClose) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_MANAGER(payload.userId, payload._id), payload);
    successNotifier('Successfully Updated');
    setLoading(false);
    onClose();
  } catch (e) {
    errorNotifier(e.response?.data.result);
  }
};

export const deleteManager = async (userId, rowId) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_MANAGER(userId, rowId));
  } catch (e) {
    errorNotifier(e.response?.data.result);
  }
};

export const banManager = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.BAN_MANAGER(payload.userId, payload._id), payload);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
  }
};

export const unbanManager = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UNBAN_MANAGER(payload.userId, payload._id), payload);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
  }
};
