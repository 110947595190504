import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const createNewManager = async (payload, setLoading, setFormValues, initialValues, onClose) => {
  const params = {
    ...payload,
    status: 'Active',
  };
  try {
    await http.post(AUTH_ROUTES.CREATE_MANAGER, params);

    setLoading(false);
    setFormValues(initialValues);
    successNotifier('Successfully created a manager');
    onClose();
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.result);
  }
};
