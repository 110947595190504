import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, VStack, Button, Input, Divider } from '@chakra-ui/react';

import { CableSubscription } from './components/CableSubscription';
import { DataSubscription } from './components/DataSubscription';
import { LightSubscription } from './components/LightSubscription';
import { getCableSubscription, getEnergySubscription, getDataSubscription, changeEstateInfo, getEstateInfo } from './service';
import { useSelector } from 'react-redux';
import FullPageLoader from '../../components/full-page-loader';
import { useHistory } from 'react-router-dom';

export const AppSetting = () => {
  const userId = useSelector((state) => state.auth.user);
  const history = useHistory();
  const [cableSubscription, setCableSubscription] = useState([]);
  const [energySubscription, setEnergySubscription] = useState([]);
  const [dataSubscription, setDataSubscription] = useState([]);
  const [loading, setLoading] = useState(true);

  const [estateDetailsLoading, setEstateDetailsLoading] = useState(false);
  const [estateInfo, setEstateInfo] = useState({});
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues(estateInfo);
  }, [estateInfo]);

  useEffect(() => {
    getCableSubscription(setLoading, userId, setCableSubscription);
    getEnergySubscription(setLoading, userId, setEnergySubscription);
    getDataSubscription(setLoading, userId, setDataSubscription);
    getEstateInfo(userId, setEstateInfo);
  }, [userId]);

  const INPUT_STYLE = {
    fontSize: '.8em',
    fontWeight: 'bold',
    color: '#3a3a3a',
    type: 'text',
    bg: '#F1F1F1',
    border: '1px solid #656363',
    borderRadius: '4',
    // height: "7",
    // w: "30%",
    _placeholder: { color: '#9b9b9b' },
  };

  const BTN_STYLE = {
    p: '5px 50px',
    color: '#fff',
    bg: '#FF6F6F',
    _hover: { bg: '#FF6F6F' },
    _focus: { boxShadow: 'none' },
  };

  const TEXT_STYLE = {
    fontSize: '.8em',
    mb: '10px',
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRedirect = () => {
    history.push('/change-password');
  };

  // const handleSaveAll = () => {
  //   window.location.href = '/app-setting';
  // };

  const handleSaveEstateInfo = () => {
    setEstateDetailsLoading(true);
    const payload = {
      ...formValues,
      userId,
    };
    changeEstateInfo(payload, setEstateDetailsLoading).then(() => (window.location.href = '/app-setting'));
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <Box bg='#fff' w='100%' borderRadius={5} p={6} pb={10} mb={5} boxShadow='md' rounded='md'>
        <Flex mb='10px' justifyContent='space-between' alignItems='center'>
          <Text fontWeight='600' color='#8366A5' mb={3}>
            Estate Information
          </Text>
          <Box as='button' border='1px solid #ff6f6f' color='#ff6f6f' padding='5px' onClick={handleRedirect}>
            Change password
          </Box>
        </Flex>
        <Divider />
        <VStack mt='10px' spacing='20px' alignItems='flex-start'>
          <Box w='100%'>
            <Text {...TEXT_STYLE}>Estate name</Text>
            <Input {...INPUT_STYLE} placeholder='Enter Estate Name' name='estateName' value={formValues?.estateName} onChange={handleChange} />
          </Box>

          <Box w='100%'>
            <Text {...TEXT_STYLE}>Estate address</Text>
            <Input placeholder='Enter Estate Address' name='address' value={formValues?.address} {...INPUT_STYLE} onChange={handleChange} />
          </Box>

          <Box w='100%'>
            <Text {...TEXT_STYLE}>Estate Dues </Text>
            <Input
              placeholder='Enter Estate Due Amount'
              name='estateDue'
              value={formValues?.estateDue}
              {...INPUT_STYLE}
              onChange={handleChange}
              type='number'
            />
          </Box>

          <Button {...BTN_STYLE} onClick={handleSaveEstateInfo} isLoading={estateDetailsLoading}>
            Save
          </Button>
        </VStack>
      </Box>
      <VStack spacing='30px' alignItems='normal'>
        <CableSubscription data={cableSubscription} />
        <LightSubscription data={energySubscription} />

        <DataSubscription data={dataSubscription} />
        {/* <Flex justifyContent='flex-end'>
          <Button {...BTN_STYLE} onClick={handleSaveAll}>
            Save all
          </Button>
        </Flex> */}
      </VStack>
    </>
  );
};
