export const PaymentTableHead = [
  "name",
  "Email",
  "Amount",
  "Balance",
  "Payment type",
  "Time",
  "Date",
  "Phone number",
];
