import { errorNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const completeErrand = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_ERRANDS(payload.userId, payload._id), payload);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
  }
};
