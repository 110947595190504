import { Tr, Td, Flex, Tooltip, Box, HStack } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { BiTrash, BiBlock } from "react-icons/bi";
import { nanoid } from "@reduxjs/toolkit";
import { Modal } from "../../../modal/index";
import EditManagerForm from "../../../../components/EditManagerForm";
import CautionAlertDialog from "../../../../components/CautionAlertDialog";
import { useSelector } from "react-redux";
import {
  banManager,
  unbanManager,
  deleteManager,
} from "../../service/getManagers";
import { FaTimes } from "react-icons/fa";
import { FaUnlockAlt } from "react-icons/fa";

const ICON = { color: "#fc8181", fontSize: "1.3em" };

export const TableBody = ({ getManagers, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);

  const handleDelete = (rowId) => {
    deleteManager(userId, rowId).then(() => updateTable());
  };

  const handleBan = (data) => {
    const payload = {
      ...data,
      status: "Banned",
      userId,
    };
    banManager(payload).then(() => updateTable());
  };

  return (
    <>
      {getManagers &&
        getManagers.map((x) => (
          <Tr key={nanoid()}>
            <Td>{x.estateName}</Td>
            <Td>{x.estateAddress}</Td>
            <Td>{x.phoneNumber}</Td>
            <Td>{x.email}</Td>
            <Td>
              <Flex justifyContent="space-between">
                <Tooltip label="Edit">
                  <Box>
                    <Modal icon={<FiEdit {...ICON} color="green" />}>
                      <EditManagerForm
                        initialData={x}
                        onRefresh={() => updateTable()}
                      />
                    </Modal>
                  </Box>
                </Tooltip>
                <Tooltip label="delete manager">
                  <Box>
                    <CautionAlertDialog
                      onContinue={() => handleDelete(x._id)}
                      cautionTitle="Are you sure you want to remove this manager"
                      icon={<BiTrash {...ICON} />}
                    />
                  </Box>
                </Tooltip>
                <Tooltip label="Ban">
                  <Box>
                    <CautionAlertDialog
                      onContinue={() => handleBan(x)}
                      cautionTitle="Are you sure you want to ban this manager"
                      icon={<BiBlock {...ICON} color="black" />}
                    />
                  </Box>
                </Tooltip>
              </Flex>
            </Td>
          </Tr>
        ))}
    </>
  );
};

export const BannedTableBody = ({ getManagers, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);
  const ICON = {
    fontSize: "1em",
    textalign: "center",
    color: "#757575",
  };

  const handleDelete = (rowId) => {
    deleteManager(userId, rowId);
    updateTable();
  };

  const handleUnban = (data) => {
    const payload = {
      ...data,
      status: "Active",
      userId,
    };
    unbanManager(payload).then(() => updateTable());
  };

  return (
    <>
      {getManagers &&
        getManagers.map((x) => (
          <Tr key={nanoid()}>
            <Td>{x.estateName}</Td>
            <Td>{x.estateAddress}</Td>
            <Td>{x.phoneNumber}</Td>
            <Td>{x.email}</Td>
            <Td>
              <HStack>
                <Tooltip label="remove">
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    bg="#d9d9d9"
                    w="25px"
                    h="25px"
                    pr={1}
                    borderRadius="50%"
                  >
                    <CautionAlertDialog
                      onContinue={() => handleDelete(x._id)}
                      cautionTitle="Are you sure you want to remove this manager?"
                      icon={<FaTimes {...ICON} />}
                    />
                  </Flex>
                </Tooltip>

                <Tooltip label="unban">
                  <Box>
                    <CautionAlertDialog
                      onContinue={() => handleUnban(x)}
                      cautionTitle="Are you sure you want to unban this manager?"
                      icon={<FaUnlockAlt {...ICON} />}
                    />
                  </Box>
                </Tooltip>
              </HStack>
            </Td>
          </Tr>
        ))}
    </>
  );
};
