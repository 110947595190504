import React, { useState } from "react";
import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Textarea } from "@chakra-ui/textarea";
import { useSelector } from "react-redux";
import { editErrandSub } from "../pages/errand/service/editErrandSub";

export const EditErrandSub = ({ onClose, initialData, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUpdateSubscription = (event) => {
    setLoading(true);
    event.preventDefault();
    const payload = {
      ...formValues,
      userId,
    };
    editErrandSub(payload, setLoading, onClose).then(() => updateTable());
  };

  return (
    <FormControl id="editSubscription" display="flex" flexDirection="column">
      <FormLabel>Subcription Name</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        name="subscriptionName"
        value={formValues.subscriptionName}
        onChange={handleChange}
      />
      <FormLabel>Number of Errands</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        name="numberOfErrands"
        value={formValues.numberOfErrands}
        onChange={handleChange}
      />
      <FormLabel>Subscription Price</FormLabel>
      <Input
        mb={4}
        variant="filled"
        type="text"
        name="packagePrice"
        value={formValues.packagePrice}
        onChange={handleChange}
      />
      <FormLabel>Description</FormLabel>
      <Textarea
        mb={8}
        variant="filled"
        h={40}
        name="description"
        value={formValues.description}
        onChange={handleChange}
      />
      <Button
        bg="#8366A5"
        _hover={{ bg: "#8366A5" }}
        _focus={{ boxShadow: "none" }}
        color="#fff"
        h={50}
        isLoading={loading}
        fontWeight="medium"
        onClick={handleUpdateSubscription}
      >
        Update
      </Button>
    </FormControl>
  );
};
