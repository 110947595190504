import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const cancelErrand = async (payload, id) => {
  try {
    await http.put(AUTH_ROUTES.CANCEL_ERRAND(payload.userId, id), payload);

    successNotifier("errand canceled");
  } catch (e) {
    errorNotifier(e.response.data.result?.message);

  }
};
