// import { errorNotifier } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from '../../../services/api';

const cardData = [
  {
    title: 'Total Errands',
    number: '0',
    key: 'totalErrands',
    color: '#A08CA8',
  },
  {
    title: 'Total Active Errands',
    number: '0',
    key: 'totalOngoingErrands',
    color: '#FF897A',
  },
  {
    title: 'Total Completed Errands',
    number: '0',
    key: 'totalCompletedErrands',
    color: '#FFBDB5',
  },
  {
    title: 'Total Completed Errands',
    number: '0',
    key: 'totalPendingErrands',
    color: '#64E270',
  },
];

//DASHBOARD ANALYTICS
export const getDashboard = async (setLoading, userId, setDashData) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_DASHBOARD(userId));

    const info = cardData.map((val) => {
      return {
        ...val,
        number: data[val.key] || val.number,
      };
    });
    setDashData(info);
  } catch (e) {
    // errorNotifier(e?.response?.data.result);
    setLoading(false);
    setDashData(cardData);
  } finally {
    setLoading(false);
  }
};
