import { Box, HStack } from "@chakra-ui/layout";
import { Td, Tr } from "@chakra-ui/table";
import { nanoid } from "@reduxjs/toolkit";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import { Modal } from "../../modal";
import EditCategoryForm from "./EditCategoryForm";
import { BiTrash } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { deleteShopCategory } from "../service/shopApi";
import { useSelector } from "react-redux";
import { Tooltip } from "@chakra-ui/tooltip";

export const CategoryBody = ({ getCategories, updateTable }) => {
  const userId = useSelector((state) => state.auth.user);
  const ICON = { color: "#fc8181", fontSize: "1.3em" };

  const handleContinue = (rowId) => {
    deleteShopCategory(userId, rowId).then(() => updateTable());
  };

  return (
    <>
      {getCategories &&
        getCategories.map((data) => {
          return (
            <Tr key={nanoid()} fontSize="smaller">
              <Td>{data.categoryName}</Td>
              <Td>{data.description}</Td>
              <Td>
                <HStack>
                  <Tooltip label="edit">
                    <Box>
                      <Modal icon={<FiEdit {...ICON} color="green" />}>
                        <EditCategoryForm
                          initialValues={data}
                          updateTable={() => updateTable()}
                        />
                      </Modal>
                    </Box>
                  </Tooltip>

                  <Tooltip label="delete">
                    <Box>
                      <CautionAlertDialog
                        onContinue={() => handleContinue(data._id)}
                        cautionTitle="Are you sure you want to remove this category?"
                        icon={<BiTrash {...ICON} />}
                      />
                    </Box>
                  </Tooltip>
                </HStack>
              </Td>
            </Tr>
          );
        })}
    </>
  );
};
