import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const addErrandType = async (
  payload,
  setLoading,
  setFormValues,
  initialValues,
  onClose
) => {
  try {
    await http.post(AUTH_ROUTES.ADD_ERRAND_TYPE, payload);
    setLoading(false);
    setFormValues(initialValues);
    successNotifier("successfully added a new errand type");
    onClose();
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data.result);
  }
};
