import { DATA_ROWS } from '../../../app/constants';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getErrandSubscription = async (setLoading, userId, setErrandSub, skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = limit * skip;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRAND_SUBSCRIPTION(userId, page, limit));
    setErrandSub(data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    setErrandSub([]);
  }
};
