import { Tr, Td } from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";

export const TableBody = ({ dataArray = [] }) => {
  const DESKTOP = { fontSize: ".7em" };
  return (
    <>
      {dataArray &&
        dataArray.map((x) => (
          <Tr bg="#fff" {...DESKTOP} key={nanoid()}>
            <Td>{x.itemName}</Td>
            <Td>{x.category} </Td>
            <Td>{x.totalView}</Td>
          </Tr>
        ))}
    </>
  );
};
