import { ErrandBoysTableHead } from "./table-data/TableHead";
import {
  BannedErrandBoysTableBody,
  ErrandBoysTableBody,
} from "./table-data/TableBody";
import { Box, Divider, Flex, Text } from "@chakra-ui/layout";
import { Modal } from "../../modal";
import AddErrandBoysForm from "../../../components/addErrandBoysForm";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { PaginatedTable } from "../../../components/PaginatedTable";
import { useEffect, useState } from "react";
import {
  getActiveErrandBoys,
  getBannedErrandBoys,
} from "../service/getErrandBoys";
import FullPageLoader from "../../../components/full-page-loader";
import { useSelector } from "react-redux";

export const ErrandBoysTable = ({ updateTable, errandBoys }) => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [activeErrandBoys, setActiveErrandBoys] = useState([]);
  const [bannedErrandBoys, setBannedErrandBoys] = useState([]);
  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    getActiveErrandBoys(setLoading, userId, setActiveErrandBoys);
    getBannedErrandBoys(setLoading, userId, setBannedErrandBoys);
  }, [refresh, userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box bg="#fff" padding="20px" borderRadius="5px">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Text color="#614285" fontSize=".9em" fontWeight="bold">
          Errand Boys
        </Text>

        <Modal title="Add Errand Boys" buttonText="Add Errand Boys">
          <AddErrandBoysForm updateTable={() => setRefresh({})} />
        </Modal>
      </Flex>
      <Divider marginBottom="5px" />

      <Tabs>
        <TabList>
          <Tab
            _focus={{ borderRadius: "none" }}
            _selected={{
              borderBottom: "4px solid #26D637",
              borderBottomRadius: "3px",
            }}
            w="120px"
          >
            Active
          </Tab>
          <Tab
            _focus={{ borderRadius: "none" }}
            _selected={{
              borderBottom: "4px solid #FF897A",
              borderBottomRadius: "3px",
            }}
            w="120px"
          >
            Banned
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <PaginatedTable
              columns={ErrandBoysTableHead}
              data={
                <ErrandBoysTableBody
                  errandBoys={activeErrandBoys}
                  updateTable={() => setRefresh({})}
                />
              }
              length={activeErrandBoys?.length}
              updateTable={(page) =>
                getActiveErrandBoys(
                  setLoading,
                  userId,
                  setActiveErrandBoys,
                  page
                )
              }
            />
          </TabPanel>
          <TabPanel>
            <PaginatedTable
              columns={ErrandBoysTableHead}
              data={
                <BannedErrandBoysTableBody
                  errandBoys={bannedErrandBoys}
                  updateTable={() => setRefresh({})}
                />
              }
              length={bannedErrandBoys?.length}
              updateTable={(page) =>
                getBannedErrandBoys(
                  setLoading,
                  userId,
                  setBannedErrandBoys,
                  page
                )
              }
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
