import React, { useState } from 'react';
import { Box, Heading, VStack } from '@chakra-ui/layout';
import { FormControl, FormLabel, Button, Input } from '@chakra-ui/react';
import { updateManager } from '../pages/Manager/service/getManagers';
import { useSelector } from 'react-redux';

export default function EditManagerForm({ initialData, onClose, onRefresh }) {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      ...formValues,
      userId,
    };
    updateManager(payload, setLoading, onClose).then(() => onRefresh(true));
  };

  return (
    <Box color='#8366A5' fontSize='12px'>
      <Heading fontSize='md' mb={5}>
        Edit Manager
      </Heading>
      <FormControl id='createCategories' display='flex' flexDirection='column'>
        <VStack spacing='20px' alignItems='normal'>
          <Box>
            <FormLabel>Estate Name</FormLabel>
            <Input
              name='estateName'
              onChange={handleChange}
              value={formValues?.estateName}
              variant='outline'
              placeholder='Enter estate name'
            />
          </Box>
          <Box>
            <FormLabel>Estate Address</FormLabel>
            <Input
              name='estateAddress'
              onChange={handleChange}
              value={formValues?.estateAddress}
              variant='outline'
              placeholder='Enter estate address'
            />
          </Box>
          <Box>
            <FormLabel>Phone Number</FormLabel>
            <Input
              name='phoneNumber'
              onChange={handleChange}
              value={formValues?.phoneNumber}
              variant='outline'
              placeholder='Enter phone number'
            />
          </Box>
          <Box>
            <FormLabel>Email Address</FormLabel>
            <Input
              name='email'
              onChange={handleChange}
              value={formValues?.email}
              variant='outline'
              placeholder='Enter email eddress'
            />
          </Box>
          <Button
            marginTop='20px'
            bg='#8366A5'
            color='#fff'
            _hover={{ bg: '#4D336A' }}
            fontWeight='medium'
            isLoading={loading}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </VStack>
      </FormControl>
    </Box>
  );
}
