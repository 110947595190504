import { Box, Flex, VStack } from "@chakra-ui/layout";
import { FormControl, FormLabel, Textarea, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Select } from "@chakra-ui/react";
import { getErrandBoys } from "../pages/errand/service/getErrandBoys";
import { useSelector } from "react-redux";
import { updateErrand } from "../pages/home/service/homeApi";

export default function AssignErrandBoyForm({ errandData, updateTable }) {
  const userId = useSelector((state) => state.auth.user);
  const [changedData, setChangedData] = useState({});
  const [errandBoys, setErrandBoys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getErrandBoys(setLoading, userId, setErrandBoys);
    return () => {
      setErrandBoys([]);
    };
  }, [userId]);

  const handleClick = () => {
    setLoading(true);
    const payload = {
      ...errandData,
      ...changedData,
      status: "ONGOING",
      userId,
    };
    updateErrand(payload, setLoading).then(() => updateTable());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChangedData({ ...changedData, [name]: value });
  };

  return (
    <FormControl id="createCategories" display="flex" flexDirection="column">
      <VStack spacing="20px" alignItems="normal">
        <Box>
          <Select
            name="name"
            placeholder="Name"
            focusBorderColor="blue"
            onChange={handleChange}
          >
            {errandBoys.map((data, i) => {
              return (
                <option key={i} value={data.name}>
                  {data.name}
                </option>
              );
            })}
          </Select>
        </Box>

        <Flex flexDirection="column">
          <FormLabel>Description</FormLabel>
          <Textarea
            name="description"
            onChange={handleChange}
            placeholder="Add errand description"
            size="lg"
            h="200px"
            value={changedData?.description}
          />
          <Button
            marginTop="20px"
            bg="#8366A5"
            color="#fff"
            _hover={{ bg: "#4D336A" }}
            fontWeight="medium"
            onClick={handleClick}
            isLoading={loading}
          >
            Submit
          </Button>
        </Flex>
      </VStack>
    </FormControl>
  );
}
