import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const assignErrand = async (payload) => {
  try {
    http.put(AUTH_ROUTES.ASSIGN_ERRAND(payload.userId, payload._id), payload);
    successNotifier("Errand assigned successfully");
  } catch (e) {
    errorNotifier(e.response.data.result?.message);
  }
};

// payload data to b set to the db.... the second parameter in a request
