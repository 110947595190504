import React from "react";
import { PaymentTableHead } from "./tables/PaymentTableHead";
import { PaymentTableBody } from "./tables/PaymentTableBody";
import { PaginatedTable } from "../../../../components/PaginatedTable";

const PaymentTable = ({ getTransactions, updateTable }) => {
  return (
    <PaginatedTable
      columns={PaymentTableHead}
      data={
        <PaymentTableBody
          getData={getTransactions}
          updateTable={() => updateTable()}
        />
      }
      length={getTransactions?.length}
      updateTable={(page) => updateTable(page)}
    />
  );
};

export default PaymentTable;
