import { PaginatedTable } from "../../../components/PaginatedTable";
import { SubscriptionTableHead } from "./table-data/TableHead";
import { SubscriptionTableBody } from "./table-data/TableBody";
import { Box, Divider, Flex, Text } from "@chakra-ui/layout";
import { Modal } from "../../modal";
import AddSubscriptionForm from "../../../components/AddSubscriptionForm";

export const SubscriptionTable = ({ errandSub, updateTable }) => {
  return (
    <Box bg="#fff" padding="20px" borderRadius="5px">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom="10px"
      >
        <Text color="#614285" fontSize=".8em" fontWeight="bold">
          Errand Subscription
        </Text>
        <Modal
          title="Add Errand Subscription"
          buttonText="Add Errand Subscription"
        >
          <AddSubscriptionForm updateTable={() => updateTable()} />
        </Modal>
      </Flex>
      <Divider marginBottom="5px" />
      <PaginatedTable
        columns={SubscriptionTableHead}
        data={
          <SubscriptionTableBody
            errandSub={errandSub}
            updateTable={() => updateTable()}
          />
        }
        length={errandSub?.errandPackage?.length}
        updateTable={(page) => updateTable(page)}
      />
    </Box>
  );
};
