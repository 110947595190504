import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { editErrandType } from "../pages/errand/service/editErrandType";

const EditErrandTypeForm = ({ updateTable, onClose, data }) => {
  const getUserId = useSelector((state) => state.auth.user);
  const [userId] = useState(getUserId);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState(data);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    setLoading(true);

    const payload = {
      ...formValues,
      userId,
    };
    editErrandType(setLoading, payload, onClose).then(() => updateTable());
  };

  return (
    <FormControl id="createCategories" display="flex" flexDirection="column">
      <FormLabel>Category Name</FormLabel>
      <Input
        mb={4}
        value={formValues.categoryName}
        variant="filled"
        type="text"
        name="categoryName"
        onChange={handleChange}
      />
      <FormLabel>Point</FormLabel>
      <Input
        mb={4}
        value={formValues.point}
        variant="filled"
        type="text"
        name="point"
        onChange={handleChange}
      />

      <FormLabel>Description</FormLabel>
      <Textarea
        mb={8}
        variant="filled"
        value={formValues.description}
        h={40}
        name="description"
        onChange={handleChange}
      />
      <Button
        bg="#8366A5"
        _hover={{ bg: "#8366A5" }}
        _focus={{ borderRadius: "none" }}
        color="#fff"
        h={50}
        fontWeight="medium"
        onClick={handleUpdate}
        isLoading={loading}
      >
        Update Errand Type
      </Button>
    </FormControl>
  );
};

export default EditErrandTypeForm;
