import { Tr, Td, Input, Button, Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateCableSubscription, updateDataSubscription, updateEnergySubscription } from '../../service/index';

const DESKTOP = { fontSize: '.68em' };

export const CableSubscriptionTableBody = ({ Data = [] }) => {
  const [companyAmount, setCompanyAmount] = useState();
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   // add total to the array
  //   let modifiedData = Data.map((item, i) => ({
  //     ...item,
  //     initialAmount: item.companyAmount,
  //     companyAmount: parseInt(item.plan_amount),
  //     planId: item.plan_id,
  //     cableId: item.cable_id,
  //     planAmount: item.plan_amount,
  //   }));
  //   // setSelected(modifiedData);
  // }, []);

  // as  they change amount value, the total should change as well
  // const handleChangeAmount = (e, id) => {
  //   const { name, value } = e.target;
  //   const updatedArray = selected.map((item) => {
  //     if (item.plan_id === id) {
  //       const add =
  //         isNaN(value) || !value
  //           ? parseInt(item.plan_amount)
  //           : parseInt(item.plan_amount) + parseInt(value);
  //       return {
  //         ...item,
  //         total: add,
  //         companyAmount: add,
  //         [name]: value,
  //         name: `${item.package} for ₦${add}`,
  //       };
  //     }
  //     return { ...item };
  //   });
  //   setSelected(updatedArray);
  // };

  // const handleSaveCableSubscription = () => {
  //   setLoading(true);
  //   const payload = {
  //     userId,
  //     data: [...selected],
  //   };
  //   updateCableSubscription(payload, setLoading);
  // };

  const saveCompanyCablePrice = (item) => {
    const payload = { userId, dataSubId: item._id, companyAmount };

    updateCableSubscription(payload, setLoading);
  };

  return (
    <>
      {Data && (
        <>
          {Data.map((item, i) => {
            return (
              <Tr key={i} bg='#fff' {...DESKTOP}>
                <Td>{item?.name}</Td>
                <Td>{item?.planAmount}</Td>

                <Td>
                  <Input
                    size='xs'
                    name='initialAmount'
                    onChange={(e) => setCompanyAmount(e.target.value)}
                    defaultValue={item?.companyAmount}
                    // value={item?.companyAmount}
                    type='number'
                  />
                </Td>

                <Td>{item?.companyAmount}</Td>

                <Td>
                  <Button
                    size='xs'
                    color='#fff'
                    bg='#FF6F6F'
                    _hover={{ bg: '#FF6F6F' }}
                    _focus={{ boxShadow: 'none' }}
                    onClick={() => saveCompanyCablePrice(item)}
                    isLoading={loading}
                  >
                    Save
                  </Button>
                </Td>
              </Tr>
            );
          })}
          {/* <Button
            p='5px 50px'
            color='#fff'
            bg='#FF6F6F'
            _hover={{ bg: '#FF6F6F' }}
            _focus={{ boxShadow: 'none' }}
            // onClick={handleSaveCableSubscription}
            isLoading={loading}
          >
            Save
          </Button> */}
        </>
      )}
    </>
  );
};

export const LightSubscriptionTableBody = ({ Data = [] }) => {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) => state.auth.user);
  useEffect(() => {
    setSelected(Data);
  }, [Data]);

  const handleSaveLightSubscription = () => {
    setLoading(true);
    const payload = { userId, data: [...selected] };
    updateEnergySubscription(payload, setLoading);
  };

  const handleChangeAmount = (e, id) => {
    const { name, value } = e.target;
    const updatedArray = selected.map((item) => {
      if (item.plan_id === id) {
        return {
          ...item,
          [name]: value,
          planId: item.plan_id,
          discoName: item.disco_name,
        };
      }
      return { ...item, planId: item.plan_id, discoName: item.disco_name };
    });
    setSelected(updatedArray);
  };

  return (
    <>
      {selected && (
        <>
          {selected.map((item, i) => {
            return (
              <Tr key={i} bg='#fff' {...DESKTOP}>
                <Td>{item?.disco_name}</Td>
                <Td>
                  <Input
                    size='xs'
                    name='amount'
                    onChange={(e) => handleChangeAmount(e, item?.plan_id)}
                    // value={item?.amount}
                    type='number'
                  />
                </Td>
              </Tr>
            );
          })}
          <Box>
            <Button
              p='5px 50px'
              color='#fff'
              bg='#FF6F6F'
              _hover={{ bg: '#FF6F6F' }}
              _focus={{ boxShadow: 'none' }}
              onClick={handleSaveLightSubscription}
              isLoading={loading}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export const DataSubscriptionTableBody = ({ Data = [] }) => {
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) => state.auth.user);
  const [companyAmount, setCompanyAmount] = useState();

  const saveCompanyDataPrice = (item) => {
    const payload = { userId, dataSubId: item._id, companyAmount };
    updateDataSubscription(payload, setLoading);
  };

  return (
    <>
      {Data && (
        <>
          {Data.map((item, i) => {
            return (
              <Tr key={i} bg='#fff' {...DESKTOP}>
                <Td>{item?.name}</Td>
                <Td>{item?.planAmount}</Td>
                <Td>
                  <Input
                    size='xs'
                    name='initialAmount'
                    onChange={(e) => setCompanyAmount(e.target.value)}
                    defaultValue={item?.companyAmount}
                    type='number'
                  />
                </Td>
                <Td>{item?.companyAmount}</Td>

                <Td>
                  <Button
                    size='xs'
                    color='#fff'
                    bg='#FF6F6F'
                    _hover={{ bg: '#FF6F6F' }}
                    _focus={{ boxShadow: 'none' }}
                    onClick={() => saveCompanyDataPrice(item)}
                    isLoading={loading}
                  >
                    Save
                  </Button>
                </Td>
              </Tr>
            );
          })}
          {/* <Box>
            <Button
              p='5px 50px'
              color='#fff'
              bg='#FF6F6F'
              _hover={{ bg: '#FF6F6F' }}
              _focus={{ boxShadow: 'none' }}
              // onClick={handleSaveDataSubscription}
              isLoading={loading}
            >
              Save
            </Button>
          </Box> */}
        </>
      )}
    </>
  );
};
