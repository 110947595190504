import { Box, Heading, SimpleGrid, Text, Flex, Button } from "@chakra-ui/react";
import FilterSubscription from "./FilterSubscription";

export const TopCards = ({
  content = null,
  getSubscription,
  filteredSubscription,
  cancelFilter,
}) => {
  if (!content) return false;
  const TEXT_ALIGN = { textAlign: ["center", "center", "left"] };

  return (
    <SimpleGrid
      columns={{ sm: 1, md: 2, lg: 2 }}
      spacing="20px"
      color="#fff"
      marginBottom="20px"
      p={["10px", "0"]}
    >
      <Flex display={["block", "block", "flex"]}>
        {content.map((data, id) => {
          return (
            <Box
              p="20px 10px"
              borderRadius="5px"
              key={id}
              w="100%"
              mr={[0, 0, 4, 4]}
              bg={data.color ? data.color : "blue.500"}
            >
              <Text {...TEXT_ALIGN}>
                <small>{data.title}</small>
              </Text>
              <Heading size="lg" {...TEXT_ALIGN}>
                {data.number}
              </Heading>
            </Box>
          );
        })}
      </Flex>
      <Box>
        <FilterSubscription
          getSubscription={getSubscription}
          filteredSubscription={filteredSubscription}
        />
        <Button
          _focus={{ borderRadius: "none" }}
          size="sm"
          colorScheme="blackAlpha"
          onClick={() => cancelFilter(true)}
          m="10px"
        >
          remove filter
        </Button>
      </Box>
    </SimpleGrid>
  );
};
