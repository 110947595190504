import { PendingTableHead } from "./table-data/TableHead";
import { PendingTableBody } from "./table-data/TableBody";
import { PaginatedTable } from "../../../components/PaginatedTable";

export const PendingTable = ({ errand, updateTable, refresh }) => {
  return (
    <PaginatedTable
      columns={PendingTableHead}
      data={<PendingTableBody errand={errand} updateTable={() => refresh()} />}
      length={errand?.length}
      updateTable={(page) => updateTable(page)}
    />
  );
};
