import "./index.css"
import {
  Box,
  Heading,
  Text,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react"
import { useState } from "react"
import dayjs from "dayjs"
import { useLocation } from "react-router-dom"
import { AiOutlineAlignLeft } from "react-icons/ai"
import { getComponentTitle } from "./components/GetComponentTitle"
import { AdBlockDetectedWrapper } from "adblock-detect-react"

export const Navigation = ({ toggle, showSidBar }) => {
  const [toggleSide] = useState(false)
  const location = useLocation()
  const pathname = location.pathname

  const handleToggle = () => {
    toggle(!toggleSide)
  }

  return (
    <Box position="relative">
      <Box
        className="navigation-inner"
        fontSize={{ sm: ".9em", md: ".9em", lg: "1em" }}
      >
        <Box
          display="grid"
          gridAutoFlow="column"
          width="100%"
          padding="20px 10px"
        >
          <Flex>
            <Box className="toggle-side-bar">
              {showSidBar ? (
                <AiOutlineAlignLeft
                  onClick={handleToggle}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                ""
              )}
            </Box>
            <Heading color="red.300" size="md" marginLeft="50px">
              {getComponentTitle(pathname)}
            </Heading>
          </Flex>
          <Box className="date">
            <Text color="#614285"> {dayjs().format("ddd, MMM D, YYYY")} </Text>
          </Box>
        </Box>
        <AdBlockDetectedWrapper>
          <Alert status="error" color="gray.800">
            <AlertIcon />
            <AlertTitle mr={2}>Ad-Blocker Detected!</AlertTitle>
            <AlertDescription fontWeight="normal">
              You may not be able to view some data. Please turn off your
              ad-blocker.
            </AlertDescription>
          </Alert>
        </AdBlockDetectedWrapper>
      </Box>
    </Box>
  )
}
