import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const deleteErrandSub = async (payload, id) => {
  try {
    await http.delete(
      AUTH_ROUTES.DELETE_SUBSCRIPTION(payload.userId, id),
      payload
    );
    successNotifier("Subscription Package deleted");
  } catch (e) {
    errorNotifier(e.response?.data?.result);
  }
};
