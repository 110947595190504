import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';
import { DATA_ROWS } from '../../../app/constants';

export const getSecurities = async (setLoading, userId, setSecurities, skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: {
          data: { securities },
        },
      },
    } = await http.get(AUTH_ROUTES.GET_SECURITIES(userId, page, limit));
    setLoading(false);
    setSecurities(securities);
  } catch (e) {
    setLoading(false);
    setSecurities([]);
    // errorNotifier(e.response?.data.result);
  }
};

export const getActiveSecurities = async (setLoading, userId, setActiveSecurities, skip = 0, limit = DATA_ROWS.LIMIT, status = 'Active') => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: {
          data: { securities },
        },
      },
    } = await http.get(AUTH_ROUTES.GET_SECURITIES(userId, page, limit, status));
    setLoading(false);
    setActiveSecurities(securities);
  } catch (e) {
    setLoading(false);
    setActiveSecurities([]);
    // errorNotifier(e.response?.data.result);
  }
};

export const getBannedSecurities = async (setLoading, userId, setBannedSecurities, skip = 0, limit = DATA_ROWS.LIMIT, status = 'Banned') => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: {
          data: { securities },
        },
      },
    } = await http.get(AUTH_ROUTES.GET_SECURITIES(userId, page, limit, status));
    setLoading(false);
    setBannedSecurities(securities);
  } catch (e) {
    setLoading(false);
    setBannedSecurities([]);
    // errorNotifier(e.response?.data.result);
  }
};

export const updateSecurity = async (payload, setLoading, onClose) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_SECURITY(payload.userId, payload._id), payload);
    successNotifier('Successfully Updated');
    setLoading(false);
    onClose();
  } catch (e) {
    errorNotifier(e.response?.data.result);
  }
};

export const deleteSecurity = async (userId, rowId) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_SECURITY(userId, rowId));
  } catch (e) {
    errorNotifier(e.response?.data.result);
  }
};

export const banSecurity = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.BAN_SECURITY(payload.userId, payload._id), payload);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
  }
};

export const unbanSecurity = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UNBAN_SECURITY(payload.userId, payload._id), payload);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
  }
};
