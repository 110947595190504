import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  Flex,
  Box,
  Textarea,
} from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CautionAlertDialog from '../../../components/CautionAlertDialog';
import FullPageLoader from '../../../components/full-page-loader';
import {
  getOfferParticipants,
  completeOffer,
  sendMessageToParticipants,
} from '../service/offer';

export const OfferSlotDetails = ({
  offerDetails,
  onClose,
  setRefresh,
  disableButtons,
}) => {
  const userId = useSelector((state) => state.auth.user);
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [message, setMessage] = useState('');
  const NO_FOCUS = {
    _focus: { boxShadow: 'none' },
    size: 'sm',
  };

  const T_HEAD = ['S/N', 'Name', 'Phone number'];

  useEffect(() => {
    getOfferParticipants(setLoading, userId, offerDetails._id, setParticipants);
  }, [offerDetails, userId]);

  const handleSendMsg = () => {
    if (!message) return;

    const filteredParticipants = participants.map((data) => {
      return data.tenantPhone;
    });
    const payload = {
      participants: filteredParticipants,
      userId,
      text: message,
    };
    sendMessageToParticipants(payload, setLoading).then(() => onClose());
  };

  const handleComplete = (offerDetails) => {
    setBtnLoading(true);
    const payload = {
      userId,
      offerId: offerDetails._id,
      status: 'Completed',
    };
    completeOffer(setBtnLoading, payload, onClose).then(setRefresh);
  };

  return loading ? (
    <FullPageLoader bg='#fff' h='100%' />
  ) : (
    <>
      <Flex justifyContent='flex-end'>
        <Text
          alignSelf='normal'
          mb='20px'
          bg='#f1f1f1'
          borderRadius='md'
          p='5px 10px'
          fontSize='sm'
        >
          {`${offerDetails.usedSlots} of ${offerDetails.initialSlots} `}
        </Text>
      </Flex>

      <Box overflow='auto' h='200px'>
        <Table variant='simple' fontSize='xs'>
          <Thead>
            <Tr>
              {T_HEAD.map((item) => {
                return (
                  <Th key={nanoid()}>
                    <small>{item}</small>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {participants &&
              participants.map((item, i) => {
                return (
                  <Tr key={nanoid()}>
                    <Td>{i + 1}</Td>
                    <Td>{item.tenantName}</Td>
                    <Td>{item.tenantPhone}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>

      {!disableButtons && (
        <>
          <Textarea
            name='text'
            isRequired
            onChange={(e) => setMessage(e.target.value)}
            placeholder='Your message...'
          />

          <Flex justifyContent='space-between' mt='20px' alignItems='center'>
            <Button
              bg='#614285'
              color='#fff'
              _hover={{ bg: '#614285' }}
              {...NO_FOCUS}
              onClick={handleSendMsg}
              isDisabled={offerDetails.usedSlots ? false : true}
            >
              Send Message
            </Button>

            <CautionAlertDialog
              size='sm'
              bg='#f1f1f1'
              btnTitle='Mark as completed'
              cautionTitle='Are you sure you want to mark as complete?'
              onContinue={() => handleComplete(offerDetails)}
              loading={btnLoading}
            />
          </Flex>
        </>
      )}
    </>
  );
};
