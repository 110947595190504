import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const editErrandSub = async (payload, setLoading, onClose) => {
  try {
    await http.put(
      AUTH_ROUTES.EDIT_ERRAND_SUBSCRIPTION(payload.userId, payload._id),
      payload
    );
    setLoading(false);
    onClose();
    successNotifier("Errand subscription edited successfully");
  } catch (e) {
    errorNotifier(e.response?.data?.result);
    setLoading(false);
  }
};
