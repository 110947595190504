import { DashboardArray } from '../component/DashboardData';
import http, { AUTH_ROUTES } from '../../../services/api';
import { errorNotifier } from '../../../components/NotificationHandler';
import { DATA_ROWS } from '../../../app/constants';

export const getDashboard = async (setLoading, userId, setDashData, skip = 0, limit = DATA_ROWS.LIMIT) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_DASHBOARD(userId, skip, limit));

    const info = DashboardArray.map((val) => {
      return {
        ...val,
        value: data[val.key],
      };
    });
    setDashData(info);
  } catch (e) {
    // errorNotifier(e?.response?.data?.result);
    setLoading(false);
    setDashData(DashboardArray);
  } finally {
    setLoading(false);
  }
};

export const getErrands = async (
  setLoading,
  userId,
  setErrands
  // skip = 0,
  // limit = DATA_ROWS.LIMIT
) => {
  // const page = limit * skip;
  const params = {
    userId,
    // skip: page,
    // limit,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRANDS(params));

    setErrands(data.errands);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const getPendingErrands = async (setLoading, userId, setPendingErrands, skip = 0, limit = DATA_ROWS.LIMIT, status = 'PENDING') => {
  const page = limit * skip;
  const params = {
    userId,
    skip: page,
    limit,
    status,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRANDS(params));

    setPendingErrands(data?.errands);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result);
    setLoading(false);
  }
};

export const getCompletedErrands = async (setLoading, userId, setCompletedErrands, skip = 0, limit = DATA_ROWS.LIMIT, status = 'COMPLETED') => {
  const page = limit * skip;
  const params = {
    userId,
    skip: page,
    limit,
    status,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRANDS(params));

    setCompletedErrands(data?.errands);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result);
    setLoading(false);
  }
};

export const getCanceledErrands = async (setLoading, userId, setCanceledErrands, skip = 0, limit = DATA_ROWS.LIMIT, status = 'CANCELED') => {
  const page = limit * skip;
  const params = {
    userId,
    skip: page,
    limit,
    status,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRANDS(params));

    setCanceledErrands(data?.errands);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result);
    setLoading(false);
  }
};

export const getOngoingErrands = async (setLoading, userId, setOngoingErrands, skip = 0, limit = DATA_ROWS.LIMIT, status = 'ONGOING') => {
  const page = limit * skip;
  const params = {
    userId,
    skip: page,
    limit,
    status,
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRANDS(params));

    setOngoingErrands(data?.errands);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data?.result);
    setLoading(false);
  }
};

export const updateErrand = async (payload, setLoading) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_ERRANDS(payload.userId, payload._id), payload);
    setLoading(false);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
    setLoading(false);
  }
};

export const cancelErrand = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_ERRANDS(payload.userId, payload._id), payload);
  } catch (e) {
    errorNotifier(e.response?.data?.result);
  }
};
