import moneyImg from "../../../assets/money.svg";
import usersImg from "../../../assets/feather-users.svg";
import renewImg from "../../../assets/renew.svg";
import storeImg from "../../../assets/store.svg";

export const DashboardArray = [
  {
    title: "Total Users",
    key: "totalUsers",
    background: "#F09091",
    value: "0",
    image: usersImg,
  },
  {
    title: "Total Subscriptions",
    background: "#CD845F",
    value: "0",
    key: "totalSubscriptions",
    image: renewImg,
  },
  {
    title: "Total Errands",
    background: "#A08CA8",
    key: "totalErrands",
    value: "0",
    image: moneyImg,
  },
  {
    title: "Total Products",
    key: "totalProducts",
    background: "#5FCD66",
    value: 0,
    image: storeImg,
  },
];
