import { Table, Thead, Tbody, Tr, Th, Box, HStack } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import FullPageLoader from './full-page-loader';
import { GrDocumentMissing } from 'react-icons/gr';

export const PaginatedTable = ({
  columns = [],
  data,
  updateTable,
  length,
  noPagination,
}) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [isEmpty, setEmpty] = useState(false);

  useEffect(() => {
    if (length < 1) {
      setEmpty(({}, () => true));
    } else {
      setEmpty(({}, () => false));
    }
  }, [length]);

  const goBack = () => {
    setPage((initial) => (initial === 0 ? initial : initial - 1));
    if (page === 0) return;
    setLoading(true);
    updateTable(page - 1).then(() => setLoading(false));
  };

  const goNext = () => {
    if (!length) {
      return;
    }
    if (length < 2) {
      return;
    }
    // console.log(length);
    setPage((initial) => initial + 1);
    setLoading(true);
    updateTable(page + 1).then(() => setLoading(false));
  };

  const ARROW_STYLE = {
    borderRadius: 'full',
    bg: 'gray.200',
    p: '10px',
    cursor: 'pointer',
  };

  return (
    <Box
      overflow='auto'
      h='450px'
      bg='#fff'
      p='10px'
      m='10px'
      borderRadius='10px'
      position='relative'
    >
      {loading ? (
        <FullPageLoader h='100%' bg='transparent' />
      ) : (
        <Table size='sm' variant='simple'>
          <Thead>
            <Tr>
              {columns.map((item) => {
                return (
                  <Th
                    color='#614285'
                    fontSize='.7em !important'
                    py='10px'
                    key={nanoid()}
                  >
                    {item}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody position='relative' fontSize='.8em'>
            {isEmpty ? (
              <Box
                position='absolute'
                transform='translate(-50%, 30%)'
                left='50%'
              >
                <GrDocumentMissing fontSize='150px' opacity='.05' />
              </Box>
            ) : (
              data
            )}
          </Tbody>
        </Table>
      )}

      {!noPagination && (
        <HStack
          spacing='20px'
          justifyContent='flex-end'
          position='absolute'
          bottom='15px'
          right='30px'
        >
          <Box {...ARROW_STYLE} onClick={goBack}>
            <BiChevronLeft />
          </Box>

          <Box {...ARROW_STYLE} onClick={goNext}>
            <BiChevronRight />
          </Box>
        </HStack>
      )}
    </Box>
  );
};
