import React, { useState } from "react";
import { Box, Text, Flex, Button } from "@chakra-ui/react";

export default function FilterSubscription({ filteredSubscription }) {
  const [inputMonth, setInputMonth] = useState("");
  const [inputDate, setInputDate] = useState("");

  const handleDateChange = (event) => {
    setInputDate(event.currentTarget.value);
  };

  const handleMonthChange = (event) => {
    setInputMonth(event.target.value);
  };

  return (
    <Box width="100%">
      <Text as="h2" color="black" mb={3} fontWeight="bold">
        Filter by:
      </Text>
      <Flex width={"100%"}>
        <select
          value={inputDate}
          onChange={(e) => handleDateChange(e)}
          style={{
            fontSize: 16,
            marginRight: 20,
            width: "33%",
            background: "#fff",
            color: "#999",
            borderRadius: 5,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <option value="">Date</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">11</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
          <option value="24">24</option>
          <option value="25">25</option>
          <option value="26">26</option>
          <option value="27">27</option>
          <option value="28">28</option>
          <option value="29">29</option>
          <option value="30">30</option>
          <option value="31">31</option>
        </select>
        <select
          value={inputMonth}
          style={{
            marginRight: 20,
            width: "33%",
            background: "#fff",
            color: "#999",
            borderRadius: 5,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          onChange={(e) => handleMonthChange(e)}
        >
          <option value="">Select Month</option>
          <option value="january">January</option>
          <option value="february">February</option>
          <option value="march">March</option>
          <option value="april">April</option>
          <option value="may">May</option>
          <option value="june">June</option>
          <option value="july">July</option>
          <option value="august">August</option>
          <option value="september">September</option>
          <option value="october">October</option>
          <option value="november">November</option>
          <option value="december">December</option>
        </select>
        <Button
          _focus={{ borderRadius: "none" }}
          style={{ background: "#ff897a" }}
          size="sm"
          onClick={() => filteredSubscription(inputMonth, inputDate)}
        >
          Apply
        </Button>
      </Flex>
    </Box>
  );
}
