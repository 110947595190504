import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Box,
} from "@chakra-ui/react";
// import { InnerTab } from "./components/InnerTab";
import { ErrandTypes } from "./components/ErrandTypes";
import { ErrandBoysTable } from "./components/ErrandBoysTable";
import { StatusInnerTab } from "./components/StatusInnerTab";
import { SubscriptionTable } from "./components/SubscriptionTable";
import { AccordionTable } from "./components/accordion-table";
import { TopCards } from "../../components/TopCards";
import AccordionHOC from "../../components/accordionHOC";
import { useEffect, useState } from "react";
import { getErrandBoys } from "./service/getErrandBoys";
import { useSelector } from "react-redux";
import FullPageLoader from "../../components/full-page-loader";
import { getErrandTypes } from "./service/getErrandTypes";
import { getErrands } from "../home/service/homeApi";
import { getErrandSubscription } from "./service/getErrandSubscriptionPackage";
import { getDashboard } from "./service/getDashboardAnalyticsApi";
import { DATA_ROWS } from "../../app/constants";

export const Errand = () => {
  const userId = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(true);
  const [errandBoys, setErrandBoys] = useState([]);
  const [errandTypes, setErrandTypes] = useState([]);
  const [errands, setErrands] = useState([]);
  const [errandSub, setErrandSub] = useState([]);
  const [dashData, setDashData] = useState([]);

  useEffect(() => {
    getDashboard(setLoading, userId, setDashData);
    getErrandBoys(setLoading, userId, setErrandBoys);
    getErrandTypes(setLoading, userId, setErrandTypes);
    getErrandSubscription(setLoading, userId, setErrandSub);
  }, [userId]);

  const TAB_TITLES = ["Status", "Subscription", "Errand Types", "Errand Boys"];
  const PADDING = ["", "", "", "0 10px"];
  const FONT_sIZE = ["xs", "sm", "md"];
  const NO_SHADOW = { boxShadow: "none" };
  const PANNEL_PADDING = {
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "8px",
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <TopCards content={dashData} />
      <AccordionHOC title="Top Errands">
        <AccordionTable errands={errands} />
      </AccordionHOC>

      <Tabs colorScheme="red">
        <TabList bg="#fff">
          {TAB_TITLES.map((title, id) => {
            return (
              <Tab
                key={id}
                outline="none !important"
                _focus={NO_SHADOW}
                borderBottom="7px solid"
              >
                <Text fontSize={FONT_sIZE} padding={PADDING}>
                  {title}
                </Text>
              </Tab>
            );
          })}
        </TabList>
        <TabPanels>
          <TabPanel {...PANNEL_PADDING}>
            <Box bg="#fff">
              <StatusInnerTab
                getErrands={errands}
                updateTable={(page) =>
                  getErrands(
                    setLoading,
                    userId,
                    setErrands
                    // page,
                    // DATA_ROWS.LIMIT
                  )
                }
              />
            </Box>
          </TabPanel>
          <TabPanel {...PANNEL_PADDING}>
            <SubscriptionTable
              errandSub={errandSub}
              updateTable={(page) =>
                getErrandSubscription(
                  setLoading,
                  userId,
                  setErrandSub,
                  page,
                  DATA_ROWS.LIMIT
                )
              }
            />
          </TabPanel>
          <TabPanel {...PANNEL_PADDING}>
            <ErrandTypes
              errandTypes={errandTypes}
              updateTable={(page) =>
                getErrandTypes(
                  setLoading,
                  userId,
                  setErrandTypes,
                  page,
                  DATA_ROWS.LIMIT
                )
              }
            />
          </TabPanel>
          <TabPanel {...PANNEL_PADDING}>
            <ErrandBoysTable
              errandBoys={errandBoys}
              updateTable={(page) =>
                getErrandBoys(setLoading, userId, setErrandBoys)
              }
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
