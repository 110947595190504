import { PaginatedTable } from "../../../components/PaginatedTable";
import { CanceledTableBody } from "./table-data/TableBody";
import { CanceledTableHead } from "./table-data/TableHead";

export const CanceledTable = ({ errand, updateTable, refresh }) => {
  return (
    <PaginatedTable
      columns={CanceledTableHead}
      data={<CanceledTableBody errand={errand} updateTable={() => refresh()} />}
      length={errand?.length}
      updateTable={(page) => updateTable(page)}
    />
  );
};
