import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";
import { ManagerPanel } from "./components/managersPanel/Manager";
import { SecurityPanel } from "./components/securityPanel/Security";
import { nanoid } from "@reduxjs/toolkit";

export const Manager = () => {
 
  const NO_PADDING = { padding: "none" };
  const PD = { padding: ["10px", "10px", "20px"] };
  return (
    <Tabs color="grey" colorScheme="yellow">
      <TabList mb={5}>
        <Tab
          key={nanoid()}
          outline="none !important"
          _focus={{ boxShadow: "none" }}
          w="180px"
          _selected={{
            borderBottom: "6px solid #ff897a",
            borderBottomRadius: "3px",
          }}
        >
          Managers
        </Tab>
        <Tab
          key={nanoid()}
          outline="none !important"
          _focus={{ boxShadow: "none" }}
          w="180px"
          _selected={{
            borderBottom: "6px solid #ff897a",
            borderBottomRadius: "3px",
          }}
        >
          Security
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel {...NO_PADDING}>
          <Box bg="#fff" {...PD}>
            <ManagerPanel
            />
          </Box>
        </TabPanel>
        <TabPanel {...NO_PADDING}>
          <Box bg="#fff" {...PD}>
            <SecurityPanel
            />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
