import { Td } from "@chakra-ui/react";

export const CableSubscriptionTableHead = () => {
  return (
    <>
      <Td> Name</Td>
      <Td> Plan Amount</Td>
      <Td>Company - Amount</Td>
      <Td>Total</Td>
    </>
  );
};

export const AirtimeSubscriptionTableHead = () => {
  return (
    <>
      <Td> Provider</Td>
      <Td> Plan Amount</Td>
      <Td> Plan-ID</Td>
      <Td>Network-ID</Td>
      <Td>Company-Amount</Td>
      <Td>Name</Td>
    </>
  );
};

export const DataSubscriptionTableHead = () => {
  return (
    <>
      <Td> Name</Td>
      <Td> Plan Amount</Td>
      {/* <Td> Plan-ID</Td>
      <Td>Network-ID</Td> */}
      <Td>Company-Amount</Td>
      <Td>Total</Td>
    </>
  );
};

export const LightSubscriptionTableHead = () => {
  return (
    <>
      <Td>Disco Name</Td>
      <Td>Amount</Td>
    </>
  );
};
