import { Table, Thead, Tbody, Tr, useMediaQuery } from "@chakra-ui/react";

export const GlobalTable = ({
  foot = null,
  tableHead = null,
  tableBody = null,
  mobileHead = null,
  mobileBody = null,
  striped = true,
}) => {
  const [isMobileScreen] = useMediaQuery("(max-width: 1100px)");

  return (
    <Table
      variant={!striped ? "" : "striped"}
      colorScheme="blackAlpha"
      bg="#fff"
      color="#575757"
      fontWeight="bold"
    >
      <Thead>
        <Tr fontSize=".7em" color="#614285" fontWeight="normal">
          {isMobileScreen && mobileHead ? mobileHead : tableHead}
        </Tr>
      </Thead>

      <Tbody>{isMobileScreen && mobileBody ? mobileBody : tableBody}</Tbody>
    </Table>
  );
};
