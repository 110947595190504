import { Button } from '@chakra-ui/button';
import { FormControl } from '@chakra-ui/form-control';
import { Box, Text, Flex } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { errorNotifier } from '../../../components/NotificationHandler';
import { sendMessage } from '../service/shopApi';

export const MessageForm = ({ tel, onClose }) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) => state.auth.user);

  const handleMessage = () => {
    if (!message) {
      errorNotifier('Input is empty');
      return;
    }
    setLoading(true);
    const payload = {
      participants: tel,
      text: message,
      userId,
    };
    sendMessage(payload, setLoading).then(() => onClose());
  };

  return (
    <Box>
      <FormControl>
        <Flex flexDirection='column'>
          <Text color='#8366a5' fontWeight='600'>
            Write Message
          </Text>
          <Textarea
            color='#464646'
            fontSize='.9em'
            mt='20px'
            name='message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            h='200px'
            _focus={{ boxShadow: 'none' }}
          />
          <Button
            mt='20px'
            color='#fff'
            bg='#8366A5'
            _hover={{ bg: '#503B6A' }}
            _focus={{ boxShadow: 'none' }}
            onClick={handleMessage}
            isLoading={loading}
          >
            Send
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
};
