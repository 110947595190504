import axios from "axios";

export * from "./routes.constants";

// prod
// export const baseURL = "https://zealightestate-app.herokuapp.com/api/v1";

// // dev
// export const baseURL = 'https://estateappbackend.herokuapp.com/api/v1';

// new dev
// export const baseURL = 'https://zealightestate-app.herokuapp.com/api/v1';
export const baseURL =
  "https://estate-app-backend-323c8861ce22.herokuapp.com/api/v1";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json ",
  },
});

const addTokenToRequest = async (req) => {
  const token = localStorage.getItem("11#221#");
  req.headers.Authorization = `Bearer ${token}`;
  return req;
};

axiosInstance.interceptors.request.use(addTokenToRequest);

export default axiosInstance;
