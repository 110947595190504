export const PendingTableHead = [
  "Errand type",
  "Name",
  "Description",
  "Options",
  "Unit",
  "Sender name",
  "Address",
  "Date",
  "Flat no.",
  "Action",
];

export const OngoingTableHead = [
  "Errand type",
  "Name",
  "Description",
  "Options",
  "Unit",
  "Sender name",
  "Address",
  "Date",
  "Flat no.",
  "Action",
];
export const CompletedTableHead = [
  "Errand type",
  "Name",
  "Description",
  "Options",
  "Unit",
  "Sender name",
  "Address",
  "Date",
  "Flat no.",
];

export const CanceledTableHead = [
  "Errand type",
  "Name",
  "Description",
  "Options",
  "Unit",
  "Sender name",
  "Address",
  "Date",
  "Flat no.",
];
export const SubscriptionTableHead = [
  "Name",
  "Description",
  "Amount",
  "Points",
  "Action",
];

export const ErrandBoysTableHead = [
  "Name",
  "Description",
  "Phone Number",
  "Estate Address",
  "Action",
];

export const ErrandTypesHead = [
  "Errand title",
  "Description",
  "Points of Errand",
  "Action",
];
